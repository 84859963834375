
type AuthorizedBuilderMarket = {
  builderName: string;
  markets: Array<{ marketName: string }>;
};

export type User = {
  authorizationToken: string;
  authorizedBuilderMarkets: AuthorizedBuilderMarket[];
};
export const useUser = () => {
  try {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    return user as User;
  } catch (e) {
    console.error("Unable to verify user");
    return {} as User;
  }
};
