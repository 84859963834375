import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import ResumeWorkWrapper from "../components/ResumeWorkWrapper";
import { AppThemeProvider } from "../providers/AppThemeProvider";
import { AppRoutes } from "./App.routes";

function App() {
  return (
    <React.Fragment>
      <AppThemeProvider>
        <ResumeWorkWrapper>
          <ToastContainer />
          <AppRoutes />
        </ResumeWorkWrapper>
      </AppThemeProvider>
    </React.Fragment>
  );
}

export default App;
