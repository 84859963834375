import useAxios from "../useAxios";
import { useQuery } from "react-query";
import { publicRoutes } from "./route";
import { publicXApiKey } from "../../../config";

const { market, community, projectedProperty, searchCategories } = publicRoutes;

export const useMarketListAll = () => {
  const callApi = useAxios();
  const { query, url, method } = market.get;
  return useQuery([query], () => {
    const headers = {
      "x-api-key": publicXApiKey,
    };
    return callApi({
      method: method,
      url: url,
      headers,
    }).then((response) => response.data);
  });
};

export const useCommunityListAll = (marketStr: string) => {
  const callApi = useAxios();
  const { query, url, method } = community.get;
  return useQuery(
    [query],
    () => {
      const headers = {
        "x-api-key": publicXApiKey,
      };
      return callApi({
        method: method,
        url: url + marketStr,
        headers,
      }).then((response) => response.data);
    },
    {
      enabled: Boolean(marketStr),
    }
  );
};

export const useProjectedProperty = (
  market: string,
  category: string,
  value: string
) => {
  const callApi = useAxios();
  const { query, url, method } = projectedProperty.get;
  return useQuery(
    [query],
    () => {
      const headers = {
        "x-api-key": publicXApiKey,
      };
      const urlQuery = (category !== "searchAll")
        ? `${url}${market}?filterKey=${category}&filterValue=${value}`
        : `${url}${market}/search/${value}`;
      return callApi({
        method: method,
        url: urlQuery,
        headers,
      }).then((response) => response.data);
    },
    {
      enabled: false,
    }
  );
};

export const useSearchCategories = (market: string) => {
  const callApi = useAxios();
  const { query, url, method } = searchCategories.get;

  return useQuery(
    [query],
    () => {
      const headers = {
        "x-api-key": publicXApiKey,
      };
      return callApi({
        method: method,
        url: url + market,
        headers,
      }).then((response) => response.data);
    },
    {
      enabled: Boolean(market),
    }
  );
};
