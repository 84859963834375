import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { DashboardDialog } from "../../components/dialogs";
import ErrorMessage from "../../components/errorMessage";
import NoFilterSelected, {
  NoDataFound,
} from "../../components/noFilterSelected";
import PieChart from "../../components/pieChart";
import {
  QuickSearchToolbar,
  StripedDataGrid,
} from "../../components/stripedDataGrid";
import StatusTooltip from "../../components/tooltip";
import {
  BuilderMarket,
  FilterType,
  useCustomContext,
} from "../../providers/ContextProvider";
import DataGridColumns from "../../shared/fixtures/DataGridColumns";
import {
  useBuilderCommunity,
  useBuilderMetrics,
} from "../../shared/hooks/private";
import { reverseArray } from "../../shared/utils";
import "./styles.css";

export type DashboardChartsProps = {
  filterValue: FilterType[];
  selectedBuilderMarket: BuilderMarket;
};

const DashboardCharts = ({
  selectedBuilderMarket,
  filterValue,
}: DashboardChartsProps) => {
  const [open, setOpen] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const isDialogClosed =
    localStorage.getItem("landingPageDialogClosed") === "true";

  const { builderName, marketName } = selectedBuilderMarket;

  const communities = filterValue.map((item) => item.value);
  const queryParams = communities
    .map((community) => `community=${encodeURIComponent(community)}`)
    .join("&");

  const {
    data: metricsData,
    error: metricsError,
    isLoading: isBuilderMetricsLoading,
  } = useBuilderMetrics(builderName, marketName, queryParams);

  const {
    data: communityData,
    isLoading: isBuilderCommunityLoading,
    error: communityError,
  } = useBuilderCommunity(builderName, marketName, queryParams);

  const isCommunityDataLoading =
    isBuilderMetricsLoading || isBuilderCommunityLoading;

  const handleTableRowHover = (index: number | null) => setHoveredIndex(index);
  interface TableDataType {
    headers: string[];
  }
  interface ColorOverviewType {
    headers: string;
    rows: Array<{ label: string; value: string }>;
  }
  const tableData: TableDataType = {
    headers: [
      "Status ",
      "# of Accounts",
      "Noticed Value",
      "Final Value",
      "Projected Taxes Due",
    ],
  };

  const builderMetrics = metricsData?.builderMetrics;
  const metricsAnalysisData = reverseArray(builderMetrics);
  const colorValues = ["", "#899096", "#3750C0", "#FCA938", "#90D03D"];

  const colorOverview: ColorOverviewType = {
    headers: "Status",
    rows: metricsAnalysisData?.map((item: any, index: number) => ({
      label: item.type === "Total" ? "Grand Total" : item.type,
      value: colorValues[index],
    })),
  };

  const totalFilteredData = metricsAnalysisData?.filter(
    (item: any) => item.type !== "Total"
  );
  const chartData = {
    labels: totalFilteredData.map((item: any) => item.type),
    values: totalFilteredData.map((item: any) => item),
    colors: colorValues.slice(1, 5),
  };

  const columns = DataGridColumns().dashboard_metrics;
  const dataGridRows = communityData?.communityMetrics || [];

  return (
    <div>
      {open && !isDialogClosed && <DashboardDialog setOpen={setOpen} />}
      <Box m="10px 0">
        {metricsError || communityError ? (
          <ErrorMessage error={(metricsError || communityError) as Error} />
        ) : null}
        <QuickSearchToolbar isDashboard={true} downloadType="metrics" />

        <Box className="dashboard-analytics" id="dashboard">
          <Table className="table">
            <TableHead>
              <TableRow>
                {tableData.headers.map((header, index) => (
                  <TableCell key={index} sx={{ textAlign: "right" }}>
                    {header === "Status " ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <span>{header}</span>
                        <StatusTooltip />
                      </Box>
                    ) : (
                      header
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isBuilderMetricsLoading && (
                <TableRow>
                  <TableCell colSpan={tableData.headers.length}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height={180}
                    >
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {!isBuilderMetricsLoading &&
                metricsAnalysisData?.length > 0 &&
                metricsAnalysisData?.map((row: any, index: number) => (
                  <TableRow
                    key={index}
                    className={
                      hoveredIndex === index ? "table-highlighted-row" : ""
                    }
                    onMouseEnter={() => handleTableRowHover(index)}
                    onMouseLeave={() => handleTableRowHover(null)}
                  >
                    <TableCell>
                      {row.type === "Total" ? "Grand Total" : row.type}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      {row.count.toLocaleString()}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      ${Math.round(row.valueNoticed).toLocaleString()}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      ${Math.round(row.valueFinal).toLocaleString()}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      ${Math.round(row.projectedTaxesDue).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          {!isBuilderMetricsLoading && metricsAnalysisData?.length > 0 && (
            <>
              <PieChart
                data={chartData}
                activeIndex={hoveredIndex}
                setActiveIndex={setHoveredIndex}
              />
              <Table className="chart-overview">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <span>{colorOverview.headers}</span>
                        <StatusTooltip />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {colorOverview.rows.map((row, index) => (
                    <TableRow
                      key={index}
                      onMouseEnter={() => handleTableRowHover(index)}
                      onMouseLeave={() => handleTableRowHover(null)}
                      className={
                        hoveredIndex === index
                          ? "color-overview-highlighted-row"
                          : ""
                      }
                    >
                      <TableCell className="color-overview-box">
                        <div
                          className="color-box"
                          style={{
                            backgroundColor: `${row.value}`,
                          }}
                        />
                        {row.label}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </Box>
        <Box id="dashboard-communities-metrics">
          {dataGridRows.length === 0 && !isCommunityDataLoading ? (
            <NoDataFound />
          ) : (
            <Box className="dashboard-datagrid-box">
              <StripedDataGrid
                className="dashboard-datagrid"
                sx={{
                  height: isCommunityDataLoading ? "500px" : null,
                }}
                rowHeight={42}
                getRowId={(option) => option.communityName}
                rows={dataGridRows}
                columns={columns}
                disableRowSelectionOnClick
                pagination
                pageSizeOptions={[10, 25, 50, 100, 250, 500]}
                loading={isCommunityDataLoading}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                slots={{
                  noRowsOverlay: NoFilterSelected,
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};
const Dashboard = () => {
  const { filterValue, selectedBuilderMarket } = useCustomContext();
  if (!selectedBuilderMarket) {
    return null;
  }

  return (
    <DashboardCharts
      selectedBuilderMarket={selectedBuilderMarket}
      filterValue={filterValue}
    />
  );
};
export default Dashboard;
