import { useRef } from "react";

export const useTimeSinceTabOpen = () => {
  const startTime = useRef(new Date().getTime());

  const getTimeSinceTabOpen = () => {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - startTime.current;
    return elapsedTime;
  };

  return { getTimeSinceTabOpen };
};

export default useTimeSinceTabOpen;
