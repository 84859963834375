import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as InfoCircle } from "../../shared/assets/icons/info-circle-light.svg";

import "./styles.css";

export const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#282C34",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#282C34",
    borderRadius: "8px",
  },
}));

export const StatusTooltipContent = () => {
  return (
    <Table className="status-tooltip-container">
      <TableBody>
        <TableRow>
          <TableCell>
            <p>Pending</p> Pending Value Release
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <p>Noticed</p> Preliminary Value Release
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <p>In Progress</p>
            In Progress of being worked with the Appraisal District
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <p>Final</p>
            Final Value for current tax year
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const StatusTooltip = () => (
  <DarkTooltip title={<StatusTooltipContent />} arrow>
    <InfoCircle />
  </DarkTooltip>
);

export default StatusTooltip;
