import axios from 'axios';
import { basePvtURL, privateXApiKey } from '../../config';
export const downloadAsExcel = (excelData: any, filename: string) => {
  const blob = new Blob([excelData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const convertCategoryFormat = (category: string) => {
  const categoryMapping: Record<string, string> = {
    Community: "community",
    "Street Name": "streetName",
    "Job Number": "jobNumber",
    "Account Number": "accountNumber",
    "Address": "streetAddress",
    "🔍 Search All Categories for": "searchAll"
  };

  return categoryMapping[category] || category;
};

export const reverseArray = (arr: string[]): string[] => {
  const reversed: string[] = [];
  for (let i = arr?.length - 1; i >= 0; i--) {
    reversed.push(arr[i]);
  }
  return reversed;
};


export const refreshAccessToken = async () => {
  console.log("Refreshing the token")
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const refreshToken = user.refreshToken;

  const headers = {
    "x-api-key": privateXApiKey,
  };
  const response = await axios.post(
    `${basePvtURL}/auth/refresh`,
    { token: refreshToken },
    { headers }
  );
  localStorage.removeItem("downloadLoadingState");
  user.authorizationToken = response?.data?.authorizationToken;
  user.authorizedBuilderMarkets = response?.data?.authorizedBuilderMarkets;
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("sessionStartedAt", new Date().getTime().toString());
  return user.authorizationToken;
};
