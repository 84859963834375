import { ReactComponent as Chart1 } from "../../shared/assets/icons/chart.svg";
import { ReactComponent as Chart2 } from "../../shared/assets/icons/chart2.svg";
import { ReactComponent as Chart3 } from "../../shared/assets/icons/chart3.svg";
import { ReactComponent as Currency } from "../../shared/assets/icons/currency.svg";

const SidebarItems = [
    {
      text: "Dashboard",
      icon: <Chart2 />,
      link: "/dashboard",
    },
    {
      text: "Estimated Property Value",
      icon: <Chart1 />,
      link: "/dashboard/estimated-property-value",
    },
    {
      text: "Estimated Taxes Due",
      icon: <Chart3 />,
      link: "/dashboard/estimated-taxes-due",
    },
    {
      text: "Estimated Tax Savings",
      icon: <Currency />,
      link: "/dashboard/estimated-tax-savings",
    },
  ];

export default SidebarItems;
