export const isLocalEnvironment = process.env.REACT_APP_IS_LOCAL_ENVIRONMENT || false;

export const baseURL = process.env.REACT_APP_PUBLIC_API_URL;
export const basePvtURL = process.env.REACT_APP_BUILDER_API_URL;
export const basePvtWebSocketURL =
  process.env.REACT_APP_BUILDER_WEB_SOCKET_API_URL;
export const clientID = process.env.REACT_APP_COGNITO_CLIENT_ID;
export const cognitoBaseURL = process.env.REACT_APP_COGNITO_BASE_URL;
export const loginRedirectURI =
  process.env.REACT_APP_COGNITO_LOGIN_REDIRECT_URL;
export const logoutRedirectURI =
  process.env.REACT_APP_COGNITO_LOGOUT_REDIRECT_URL;
export const publicXApiKey = process.env.REACT_APP_PUBLIC_X_API_KEY;
export const privateXApiKey = process.env.REACT_APP_BUILDER_X_API_KEY;

export const cognitoLoginURL = `${cognitoBaseURL}/login?client_id=${clientID}&response_type=code&scope=email+openid+profile&redirect_uri=${loginRedirectURI}`;
export const cognitoLogoutURL = `${cognitoBaseURL}/logout?client_id=${clientID}&logout_uri=${logoutRedirectURI}`;

export const awsRumApplicationId = process.env.REACT_APP_AWS_RUM_APPLICATION_ID;
export const awsRumApplicationVersion =
  process.env.REACT_APP_AWS_RUM_APPLICATION_VERSION;
export const awsRumApplicationRegion =
  process.env.REACT_APP_AWS_RUM_APPLICATION_REGION;
export const awsRumConfigEndpoint =
  process.env.REACT_APP_AWS_RUM_CONFIG_ENDPOINT;
export const awsRumIdentityPoolId =
  process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID;
export const awsRumGuestRoleArn = process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN;

export const muiProKey = process.env.REACT_APP_MUI_X_PRO_LICENSE;
export const webSocketPingPongIntervalMS = Number(process.env.WEB_SOCKET_PING_PONG_INTERVAL_MS) || 50_000;
