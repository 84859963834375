import { useMutation, useQuery } from "react-query";
import { isLocalEnvironment, privateXApiKey } from "../../../config";
import { useCustomContext } from "../../../providers/ContextProvider";
import useAxios from "../useAxios";
import { privateRoutes } from "./route";

const {
  oauth2Login,
  metrics,
  community,
  estimatedValue,
  estTaxesDue,
  estTaxSavings,
  downloadMetrics,
  downloadValueEstimates,
  downloadSummary,
  downloadDetail,
  downloadSavings,
  downloadAllData,
  downloadNow,
  communitiesFilter,
  downloadAsync,
  downloadReady,
} = privateRoutes;

export const useAuth2Login = () => {
  const callApi = useAxios();
  const { url, method } = oauth2Login.post;
  return useMutation((code: string) => {
    const headers = { "x-api-key": privateXApiKey };
    const data = {
      code: code,
      useCase: isLocalEnvironment ? "localhostBuilderApp" : "builderApp",
    };
    return callApi({
      method: method,
      url: url,
      data,
      headers,
    }).then((response) => response.data);
  });
};

export const useBuilderMetrics = (
  builder: string,
  market: string,
  communities: string
) => {
  const callApi = useAxios();
  const { query, url, method } = metrics.get;

  return useQuery(
    [query, builder, market, communities],
    () => {
      const headers = { "x-api-key": privateXApiKey };
      return callApi({
        method: method,
        url: `${url}/${builder}/${market}/?${communities}`,
        headers,
      }).then((response) => response.data);
    },
    {
      enabled: Boolean(builder && market),
    }
  );
};

export const useBuilderCommunity = (
  builder: string,
  market: string,
  communities: string
) => {
  const callApi = useAxios();
  const { query, url, method } = community.get;

  return useQuery(
    [query, builder, market, communities],
    () => {
      const headers = { "x-api-key": privateXApiKey };
      return callApi({
        method: method,
        url: `${url}/${builder}/${market}/?${communities}`,
        headers,
      }).then((response) => response.data);
    },
    {
      enabled: Boolean(builder && market),
    }
  );
};

export const useEstimatedValue = (
  builder: string,
  market: string,
  communities: string
) => {
  const callApi = useAxios();
  const { query, url, method } = estimatedValue.get;

  return useQuery(
    [query, builder, market, communities],
    () => {
      const headers = { "x-api-key": privateXApiKey };
      return callApi({
        method: method,
        url: `${url}/${builder}/${market}/?${communities}`,
        headers,
      }).then<{ properties: Array<Property> }>((response) => response.data);
    },
    {
      enabled: Boolean(builder && market && communities),
    }
  );
};

export type Property = {
  value: string;
  communityName: string;
  streetName: string;
  jobNumber: string;
  accountNumber: string;
  referenceNumber: string;
};

export const useEstTaxesDue = (
  builder: string,
  market: string,
  communities: string
) => {
  const callApi = useAxios();
  const { query, url, method } = estTaxesDue.get;

  return useQuery(
    [query, builder, market, communities],
    () => {
      const headers = { "x-api-key": privateXApiKey };
      return callApi({
        method: method,
        url: `${url}/${builder}/${market}/?${communities}`,
        headers,
      }).then<{ properties: Array<Property> }>((response) => response.data);
    },
    {
      enabled: Boolean(builder && market && communities),
    }
  );
};

export const useEstTaxSavings = (
  builder: string,
  market: string,
  communities: string
) => {
  const callApi = useAxios();
  const { query, url, method } = estTaxSavings.get;

  return useQuery(
    [query, builder, market, communities],
    () => {
      const headers = { "x-api-key": privateXApiKey };
      return callApi({
        method: method,
        url: `${url}/${builder}/${market}/?${communities}`,
        headers,
      }).then<{ properties: Array<Property> }>((response) => response.data);
    },
    {
      enabled: Boolean(builder && market && communities),
    }
  );
};

export const useDownloadMetrics = (builder: string, market: string) => {
  const callApi = useAxios();
  const { url, method } = downloadMetrics.post;
  return useMutation((data) => {
    const headers = { "x-api-key": privateXApiKey };
    return callApi({
      method: method,
      url: url + builder + "/" + market,
      data,
      headers,
    }).then((response) => response.data);
  });
};

export const useDownloadValueEstimates = (builder: string, market: string) => {
  const callApi = useAxios();
  const { url, method } = downloadValueEstimates.post;
  return useMutation((data) => {
    const headers = { "x-api-key": privateXApiKey };
    return callApi({
      method: method,
      url: url + builder + "/" + market,
      data,
      headers,
    }).then((response) => response.data);
  });
};

export const useDownloadSummary = (builder: string, market: string) => {
  const callApi = useAxios();
  const { url, method } = downloadSummary.post;
  return useMutation((data) => {
    const headers = { "x-api-key": privateXApiKey };
    return callApi({
      method: method,
      url: url + builder + "/" + market,
      headers,
      data,
    }).then((response) => response.data);
  });
};

export const useDownloadDetail = (builder: string, market: string) => {
  const callApi = useAxios();
  const { url, method } = downloadDetail.post;
  return useMutation((data) => {
    const headers = { "x-api-key": privateXApiKey };
    return callApi({
      method: method,
      url: url + builder + "/" + market,
      headers,
      data,
    }).then((response) => response.data);
  });
};

export const useDownloadSavings = (builder: string, market: string) => {
  const callApi = useAxios();
  const { url, method } = downloadSavings.post;
  return useMutation((data) => {
    const headers = { "x-api-key": privateXApiKey };
    return callApi({
      method: method,
      url: url + builder + "/" + market,
      headers,
      data,
    }).then((response) => response.data);
  });
};

export const useDownloadAllData = (builder: string, market: string) => {
  const callApi = useAxios();
  const { url, method } = downloadAllData.post;
  return useMutation((data) => {
    const headers = { "x-api-key": privateXApiKey };
    return callApi({
      method: method,
      url: url + builder + "/" + market,
      headers,
      data,
    }).then((response) => response.data);
  });
};

export const useDownloadNow = () => {
  const callApi = useAxios();
  const { url, method } = downloadNow.post;
  return useMutation((data: any) => {
    const headers = {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "x-api-key": privateXApiKey,
    };
    return callApi({
      method: method,
      url: url,
      data,
      headers,
      responseType: "arraybuffer",
    }).then((response) => response);
  });
};

export const useCommunitiesFilters = (builder: string, market: string) => {
  const callApi = useAxios();
  const { query, url, method } = communitiesFilter.get;

  return useQuery(
    [query, builder, market],
    () => {
      const headers = { "x-api-key": privateXApiKey };
      return callApi({
        method: method,
        url: `${url}/${builder}/${market}`,
        headers,
      }).then((response) => response.data);
    },
    { enabled: Boolean(builder && market) }
  );
};

export const useDownloadAsync = () => {
  const callApi = useAxios();
  const { url, method } = downloadAsync.post;
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const userId = user.userId;
  const { websocketConnection, isDownloadLoading, setIsDownloadLoading } =
    useCustomContext();

  return useMutation(async (data: any) => {
    const headers = { "x-api-key": privateXApiKey };
    return callApi({
      method,
      url,
      data,
      headers,
    }).then((response) => {
      if (websocketConnection) {
        const message: { action: string; requestId: string; userId: string } = {
          action: "subscribe",
          userId: userId,
          requestId: response.data.requestId,
        };
        websocketConnection.send(JSON.stringify(message));
        setIsDownloadLoading([
          ...isDownloadLoading,
          {
            type: response.data.downloadType,
            requestId: response.data.requestId,
          },
        ]);
      }
      return response.data;
    });
  });
};

export const useDownloadReady = () => {
  const callApi = useAxios();
  const { url, method } = downloadReady.get;

  return useMutation((requestId: string) => {
    const headers = {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "x-api-key": privateXApiKey,
    };

    return callApi({
      method: method,
      url: `${url}/${requestId}`,
      headers,
      responseType: "arraybuffer",
    }).then((response) => response);
  });
};
