import { Close } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./styles.css";

type StepConfig  = {
  elementId: string
  title: string
  description: string
  top: number
  left?: number
  arrowTop?: number
  arrowLeft?: number
  arrowRotate?: number
}

export type TourGuideProps = {
  tourSteps: Array<StepConfig>;
  name?: string
}
const TourGuide = ({ tourSteps, name="tourGuideStep" }: TourGuideProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [tourDialogOpened, setTourDialogOpened] = useState(true);
  const [tourGuideStyle, setTourGuideStyle] = useState<
    React.CSSProperties | undefined
  >();
  const [arrowStyle, setArrowStyle] = useState<
    React.CSSProperties | undefined
  >();

  const storedStep = localStorage.getItem(`${name}`);
  const isTourFinished = localStorage.getItem(`${name}-finished`);
  const dialogElement = document.getElementById("tour-guide-dialog");

  useEffect(() => {
    if (storedStep) setCurrentStep(parseInt(storedStep, 10));
  }, []);

  const goToNextStep = () => {
    if (currentStep === tourSteps.length - 1) {
      if (dialogElement) {
        dialogElement.style.display = "none";
      }
      localStorage.setItem(`${name}-finished`, "true");
      document.body.classList.remove("no-scroll");
      element?.classList.remove("highlighted-component");
      return;
    }

    setCurrentStep((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    localStorage.setItem(`${name}`, currentStep.toString());
  }, [currentStep]);

  // @ts-ignore
  const step: {
    elementId: string;
    title: string;
    description: string;
    top?: number;
    left?: number;
    arrowTop?: number;
    arrowLeft?: number;
    arrowRotate?: number;
    right?: number;
  } = tourSteps[currentStep];
  const element = document.getElementById(step.elementId);

  useEffect(() => {
    !tourDialogOpened && element?.classList.remove("highlighted-component");
  }, [tourDialogOpened]);

  useEffect(() => {
    if (tourDialogOpened && !isTourFinished && element) {
      const { top, left } = element.getBoundingClientRect();
      element.classList.add("highlighted-component");
      document.body.classList.add("no-scroll");

      setTourGuideStyle({
        top: top + (step?.top ?? 0),
        left: (step.left || step.left === 0) ? left + step.left : undefined,
        right: (step.right || step.right === 0) ? step.right : undefined,
      });

      setArrowStyle({
        top: (step?.arrowTop === 0)
          ? `calc(${step?.arrowTop ?? 0}% - 15px)`
          : (step?.arrowTop === 100)
            ? `calc(${step?.arrowTop ?? 0}% - 5px)`
            : `calc(${step?.arrowTop ?? 0}%)`,
        left: (step?.arrowLeft === 0)
            ? `calc(${step?.arrowLeft ?? 0}% - 10px)`
            : (step?.arrowLeft === 100)
              ? `calc(${step?.arrowLeft ?? 0}% + 10px)`
              : `calc(${step?.arrowLeft ?? 0}% - 5px)`,
        transform: `rotate(${step?.arrowRotate ?? 0}deg)`,
      });
    }

    return () => {
      if (element) {
        element.classList.remove("highlighted-component");
      }
    };
  }, [step, element]);

  const handleCancelTour = () => {
    document.body.classList.remove("no-scroll");
    element?.classList.remove("highlighted-component");
    setTourDialogOpened(false);
  };

  return tourDialogOpened && !isTourFinished && element ? (
    <Box id="tour-guide-dialog" className="tour-guide-background">
      <Box style={tourGuideStyle} className="tour-guide">
        <IconButton
          className="close-tour-dialog"
          aria-label="close"
          onClick={handleCancelTour}
        >
          <Close />
        </IconButton>
        <Typography className="step-title">{step.title}</Typography>
        <Typography className="step-desc">{step.description}</Typography>
        <Divider className="footer-divider" />
        <Box style={arrowStyle} className="tour-guide-arrow"></Box>
        <Box className="tour-guide-footer">
          <Typography className="footer-count">
            Tour Guide {currentStep + 1} of {tourSteps.length}
          </Typography>
          <Button className="footer-btn" variant="text" onClick={goToNextStep}>
            {currentStep === tourSteps.length - 1 ? "FINISH" : "NEXT"}
          </Button>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default TourGuide;
