type messageType = {
  error?: Error;
};

const ErrorMessage = ({ error }: messageType) => {
  return (
    <div>
      <p>
        Something went wrong! We apologize for the inconvenience.
        {error ? (
          <b> {error.message}</b>
        ) : (
          " Kindly attempt to retry at a later time."
        )}
      </p>
    </div>
  );
};

export default ErrorMessage;
