import { useMemo } from "react";
import { Property } from "./index";

export function useFilterPropertiesBySearch(
  properties: Property[],
  searchedData: { category: string; value: string; sort: string }
) {
  return useMemo(() => {
    return properties?.filter((item: any) => {
      switch (searchedData?.category) {
        case "communityName":
          return item.communityName === searchedData?.value;
        case "streetName":
          return item.streetName === searchedData?.value;
        case "jobNumber":
          return item.jobNumber === searchedData?.value;
        case "accountNumber":
          return item.accountNumber === searchedData?.value;
        case "referenceNumber":
          return item.referenceNumber === searchedData?.value;
        default:
          return true;
      }
    });
  }, [properties, searchedData]);
}
