import { Box, Button, CssBaseline, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../../components/wrapper";

const Home: React.FC = () => {
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <CssBaseline />
      <Box className="main-body">
        <Typography className="tag-line">
          Reduce your Property Taxes with Ad Valorem Advisors
        </Typography>
        <Box className="btn-container" display="flex" justifyContent="center">
          <Button variant="outlined" onClick={() => navigate("/builder")}>
            Builder Estimates
          </Button>
          <Button variant="outlined" onClick={() => navigate("/contact")}>
            Contact Us
          </Button>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Home;
