import { Button, CircularProgress } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useCustomContext } from "../../providers/ContextProvider";
import { ReactComponent as DownloadIcon } from "../../shared/assets/icons/download.svg";
import { useDownloadAsync } from "../../shared/hooks/private";
import { DownloadErrorInfoDialog } from "../dialogs";

interface Props {
  payloadData: {
    downloadType: string;
    builderName: string;
    marketName: string;
    selected?: GridRowId[];
    filter?: {
      filterKey: string;
      filterValue: string;
    };
    communities?: string[];
  };
  isDashboard?: boolean;
}

const ExportToExcel: React.FC<Props> = ({ payloadData, isDashboard }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [workResumeDialogOpen, setWorkResumeDialogOpen] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const {
    filterValue,
    websocketConnection,
    isDownloadLoading,
    setIsDownloadLoading,
    connectWebSocketAndListen,
  } = useCustomContext();

  const {
    isLoading: isDownloadAsyncLoading,
    mutate: requestAsyncData,
    error: downloadAsyncError,
  } = useDownloadAsync();


  const requestDownloadData = () => {
    // make a fresh new websocket connection to avoid all risk of a stale connection
    const newWebSocketConnection = connectWebSocketAndListen();
    // then make the download request with the new connection
    newWebSocketConnection ? requestAsyncData(payloadData) : setOpen(true);
  };

  useEffect(() => {
    if (isDownloadLoading.length > 0) {
      const isRequestLoading = isDownloadLoading.some(
        (item) => item.type === payloadData.downloadType
      );
      setIsBtnLoading(isRequestLoading);
    } else {
      setIsBtnLoading(false);
    }
  }, [isDownloadLoading]);

  useEffect(() => {
    if (downloadAsyncError) {
      setOpen(true);
      setIsDownloadLoading(
        isDownloadLoading.filter(
          (item) => item.type !== payloadData.downloadType
        )
      );
    }
  }, [downloadAsyncError]);

  const error = downloadAsyncError;
  const isLoading = isDownloadAsyncLoading || isBtnLoading;

  return (
    <React.Fragment>
      <DownloadErrorInfoDialog
        open={open}
        setOpen={setOpen}
        error={error as Error}
      />
      <Button
        id="download-this-data-set"
        className={
          isLoading || (!isDashboard && filterValue.length === 0)
            ? "loading-btn-box"
            : "active-filter"
        }
        onClick={requestDownloadData}
        disabled={isLoading || (!isDashboard && filterValue.length === 0)}
      >
        {isLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <DownloadIcon />
        )}
        <p>Download This Data Set</p>
      </Button>
    </React.Fragment>
  );
};

export default ExportToExcel;
