import { Route, Routes } from "react-router-dom";
import NotFound from "../components/NotFound";
import ProtectedRoutes from "../components/protectedRoutes";
import BuilderMarketLandingPage from "../pages/BuilderMarketLandingPage";
import Dashboard from "../pages/dashboard/Dashboard";
import EstTaxDue from "../pages/dashboard/EstTaxDue";
import EstTaxSavings from "../pages/dashboard/EstTaxSavings";
import ValueEstimates from "../pages/dashboard/ValueEstimates";
import About from "../pages/public/About";
import Builder from "../pages/public/Builder";
import Contact from "../pages/public/Contact";
import FAQs from "../pages/public/FAQs";
import Home from "../pages/public/Home";
import Main from "../pages/public/Main";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Main />}>
        <Route index element={<Home />} />
        <Route path="/builder" element={<Builder />} />
        <Route path="/about" element={<About />} />
        <Route path="/FAQ" element={<FAQs />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
      <Route path="/dashboard" element={<ProtectedRoutes />}>
        <Route index element={<Dashboard />} />
        <Route path="estimated-property-value" element={<ValueEstimates />} />
        <Route path="estimated-taxes-due" element={<EstTaxDue />} />
        <Route path="estimated-tax-savings" element={<EstTaxSavings />} />
      </Route>
      <Route element={<ProtectedRoutes hasDashboardNavBar={false} />}>
        <Route path="select-builder-market" element={<BuilderMarketLandingPage />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
