import { Box, Divider, Typography, useTheme } from "@mui/material";
import { ReactComponent as Title } from "../../shared/assets/icons/LogoTitle.svg";
import "./styles.css";

const Footer = () => {
  const { spacing } = useTheme();
  return (
    <Box p={`${spacing(3)} 0`} className="container">
      <Box>
        <Divider orientation="horizontal" flexItem variant="middle" />
        <Box p={spacing(2)}>
          <Title />
          <Typography>2023 © All right reserved</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
