import {
  CssBaseline,
  createTheme,
  ThemeProvider,
  ThemeOptions,
} from "@mui/material";
import React, { useMemo } from "react";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    text: {
      primary: "#40454F",
      secondary: "#777D89",
    },
    grey: { "800": "#424242" },
    common: {
      white: "#fff",
      black: "#000",
    },
    background: {
      default: "#fbfcfd",
    },
    success: {
      main: "#153226",
      light: "#99D52A",
      contrastText: "#94BA2C",
    },
    warning: {
      main: "#ED6C02",
    },
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#99D52A"
    }
  },
  typography: {
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontFamily: ["Inter", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "text",
      },
    },
  },
};

export const AppThemeProvider = ({ children }: React.PropsWithChildren) => {
  const theme = useMemo(() => createTheme(themeOptions), []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
