import {
  Box,
  Card,
  CardHeader,
  Container,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { GridClearIcon, GridSearchIcon } from "@mui/x-data-grid";
import { useDeferredValue, useEffect, useMemo, useState } from "react";
import { BuilderMarket, useCustomContext } from "../providers/ContextProvider";

const BuilderMarketLandingPage = () => {
  const { selectedBuilderMarket, setSelectedBuilderMarket, builderMarkets } =
    useCustomContext();

  const [query, setQuery] = useState("");
  const deferredQuery = useDeferredValue(query);

  /**
   * If there is only one builder market,
   * we want it to be the selected one.
   */
  useEffect(() => {
    if (
      builderMarkets?.length === 1 &&
      (!selectedBuilderMarket || selectedBuilderMarket !== builderMarkets[0])
    ) {
      setSelectedBuilderMarket(builderMarkets[0]);
    }
  }, [builderMarkets]);
  const groupedMarketsEntries = useMemo(() => {
    const map = builderMarkets.reduce(
      (map: Record<string, Array<BuilderMarket>>, elm: any) => {
        if (!map[elm.marketName]) {
          map[elm.marketName] = [];
        }
        map[elm.marketName].push(elm);
        return map;
      },
      {}
    );

    // sort the builders
    for (const key in map) {
      map[key] = map[key].sort((a, b) =>
        a.builderName.localeCompare(b.builderName)
      );
    }

    // sort the markets, move _TEST to last
    const entries = Object.entries(map).sort((a, b) => {
      if (a[0] === "_TEST") {
        return 1;
      }
      if (b[0] === "_TEST") {
        return -1;
      }

      return a[0].localeCompare(b[0]);
    });

    return entries;
  }, [builderMarkets]);

  const maxBuilders = useMemo(
    () =>
      groupedMarketsEntries.reduce(
        (max, [_, builderMarkets]) => Math.max(max, builderMarkets.length),
        -Infinity
      ),
    [groupedMarketsEntries]
  );

  const displaySearchBox = maxBuilders > 6;

  return (
    <Container id="builder-market-menu" maxWidth="xl">
      <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
        <Typography variant="h2" align="center">
          Select a Builder-Market to begin.
        </Typography>
        {displaySearchBox ? (
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Filter Builders"
              value={query}
              inputProps={{ "aria-label": "Filter Builders" }}
              onChange={(e) => setQuery(e.target.value.toLocaleLowerCase())}
            />
            {query.length == 0 ? (
              <GridSearchIcon sx={{ margin: 1 }} />
            ) : (
              <IconButton onClick={() => setQuery("")}>
                <GridClearIcon />
              </IconButton>
            )}
          </Paper>
        ) : null}
      </Box>

      <Grid container spacing={3} marginTop={2}>
        {groupedMarketsEntries.map(([marketName, builderMarkets]) => (
          <Grid item key={marketName} lg={2.4} md={4} sm={6}>
            <BuilderMarketCard
              marketName={marketName}
              builderMarkets={builderMarkets}
              query={deferredQuery}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

type BuilderMarketCardProps = {
  marketName: string;
  builderMarkets: BuilderMarket[];
  query: string;
};

function BuilderMarketCard({
  marketName,
  builderMarkets,
  query,
}: BuilderMarketCardProps) {
  const { selectedBuilderMarket, setSelectedBuilderMarket } =
    useCustomContext();

  const handleBuilderMarketSelect = (builderMarket: BuilderMarket) => {
    setSelectedBuilderMarket(builderMarket);
  };

  const filteredBuilderMarkets = builderMarkets.filter((bm) =>
    bm.builderName.toLocaleLowerCase().includes(query)
  );
  return (
    <Card>
      <CardHeader
        title={marketName}
        sx={{
          borderBottom: `1px solid ${grey[300]}`,
        }}
      />
      <List
        sx={{
          maxHeight: 300,
          overflowY: "auto",
        }}
      >
        {filteredBuilderMarkets.length > 0 ? (
          filteredBuilderMarkets.map((builderMarket) => (
            <ListItemButton
              key={builderMarket.label}
              selected={
                !!selectedBuilderMarket &&
                selectedBuilderMarket?.label === builderMarket.label
              }
              onClick={() => handleBuilderMarketSelect(builderMarket)}
            >
              {builderMarket.builderName}
            </ListItemButton>
          ))
        ) : (
          <ListItemText>
            <Typography padding={2} align="center" variant="body2" color="grey">
              No results found
            </Typography>
          </ListItemText>
        )}
      </List>
    </Card>
  );
}
export default BuilderMarketLandingPage;
