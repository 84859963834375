import React from "react";
import bgImage from "../../shared/assets/images/homeBgImage.png";
import { Box, useTheme } from "@mui/material";
import "./styles.css";

type PageWrapper = {
  children: React.ReactNode;
  minHeight?: string;
  backgroundOpacity?: string;
};

const PageWrapper = ({
  children,
  minHeight = "73vh",
  backgroundOpacity = "0.1",
}: PageWrapper) => {
  const { palette } = useTheme();
  const backgroundStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, ${backgroundOpacity}), rgba(0, 0, 0, ${backgroundOpacity})), url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <Box
      className="page-wrapper-container"
      minHeight={minHeight}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      color={palette.common.white}
      sx={backgroundStyle}
    >
      {children}
    </Box>
  );
};

export default PageWrapper;
