import { AwsRum } from "aws-rum-web";
import {
  awsRumApplicationId,
  awsRumApplicationRegion,
  awsRumApplicationVersion,
  awsRumConfigEndpoint,
  awsRumGuestRoleArn,
  awsRumIdentityPoolId,
} from "../config";

const config = {
  sessionSampleRate: 1,
  guestRoleArn: awsRumGuestRoleArn,
  identityPoolId: awsRumIdentityPoolId,
  endpoint: awsRumConfigEndpoint,
  telemetries: ["performance", "errors", "http"],
  allowCookies: true,
  enableXRay: true,
};

const APPLICATION_ID = awsRumApplicationId || "";
const APPLICATION_VERSION = awsRumApplicationVersion || "";
const APPLICATION_REGION = awsRumApplicationRegion || "";

const awsRum = new AwsRum(
  APPLICATION_ID,
  APPLICATION_VERSION,
  APPLICATION_REGION,
  config
);

export default awsRum;
