import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type OptionsProp = {
  category: string;
  value: string;
  sort: string;
};

export default function CheckboxAutoComplete(props: any) {
  const { options } = props;

  return (
    <Autocomplete
      id="sidebar-communities"
      options={options}
      fullWidth
      disableCloseOnSelect
      disableClearable
      forcePopupIcon={false}
      size="small"
      clearOnBlur={false}
      limitTags={10}
      ChipProps={{
        sx: {
          height: "auto",
          padding: "4px 2px",
          lineHeight: 1.125,
          "& .MuiChip-label": {
            display: "block",
            whiteSpace: "normal",
          },
        },
      }}
      isOptionEqualToValue={(option: OptionsProp, value: any) =>
        option.value === value.value
      }
      getOptionLabel={(option: OptionsProp) => option.value}
      renderOption={(props, option: OptionsProp, { selected }) => (
        <li {...props} style={{ lineHeight: 1.5 }}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          <span style={{ lineHeight: "1.25" }}>{option.value}</span>
        </li>
      )}
      {...props}
    />
  );
}
