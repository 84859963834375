import { useMemo } from "react";
import { Property } from "./index";

export const useSearchIndex = (properties: Array<Property>) => {
  return useMemo(() => {
    const streetNames = new Set();
    const jobNumbers = new Set();
    const accountNumbers = new Set();
    const referenceNumbers = new Set();

    properties.forEach((property) => {
      if (property.streetName) {
        streetNames.add(property.streetName);
      }

      if (property.jobNumber) {
        jobNumbers.add(property.jobNumber);
      }

      if (property.accountNumber) {
        accountNumbers.add(property.accountNumber);
      }

      if (property.referenceNumber) {
        referenceNumbers.add(property.referenceNumber);
      }
    });

    return {
      streetNames: [...streetNames],
      jobNumbers: [...jobNumbers],
      accountNumbers: [...accountNumbers],
      referenceNumbers: [...referenceNumbers],
    };
  }, [properties]);
};
