import { basePvtURL } from "../../../config";

export const privateRoutes = {
  oauth2Login: {
    post: {
      query: "OAUTH2_LOGIN",
      method: "POST",
      url: `${basePvtURL}/auth`,
    },
  },
  metrics: {
    get: {
      query: "GET_METRICS",
      method: "GET",
      url: `${basePvtURL}/metrics`,
    },
  },
  community: {
    get: {
      query: "GET_COMMUNITIES",
      method: "GET",
      url: `${basePvtURL}/communities`,
    },
  },
  estimatedValue: {
    get: {
      query: "GET_ESTIMATED_VALUE",
      method: "GET",
      url: `${basePvtURL}/estimated-value`,
    },
  },
  estTaxesDue: {
    get: {
      query: "GET_ESTIMATED_TAXES_DUE_DETAIL",
      method: "GET",
      url: `${basePvtURL}/estimated-taxes-due`,
    },
  },
  estTaxSavings: {
    get: {
      query: "GET_ESTIMATED_TAX_SAVINGS",
      method: "GET",
      url: `${basePvtURL}/estimated-tax-savings`,
    },
  },
  downloadMetrics: {
    post: {
      query: "REQUEST_DOWNLOAD_METRICS",
      method: "POST",
      url: `${basePvtURL}/downloads/metrics/`,
    },
  },
  downloadValueEstimates: {
    post: {
      query: "REQUEST_DOWNLOAD_ESTIMATED_VALUE",
      method: "POST",
      url: `${basePvtURL}/downloads/estimated-value/`,
    },
  },
  downloadSummary: {
    post: {
      method: "POST",
      url: `${basePvtURL}/downloads/estimated-taxes-due/summary/`,
    },
  },
  downloadDetail: {
    post: {
      method: "POST",
      url: `${basePvtURL}/downloads/estimated-taxes-due/detail/`,
    },
  },
  downloadSavings: {
    post: {
      method: "POST",
      url: `${basePvtURL}/downloads/estimated-savings/`,
    },
  },
  downloadAllData: {
    post: {
      method: "POST",
      url: `${basePvtURL}/downloads/all/`,
    },
  },
  downloadNow: {
    post: {
      method: "POST",
      url: `${basePvtURL}/downloads/now/`,
    },
  },
  communitiesFilter: {
    get: {
      query: "GET_COMMUNITIES_FILTERS",
      method: "GET",
      url: `${basePvtURL}/filters`,
    },
  },
  downloadAsync: {
    post: {
      method: "POST",
      url: `${basePvtURL}/downloads/async/`,
    },
  },
  downloadReady: {
    get: {
      query: "DOWNLOAD_FILE_READY",
      method: "GET",
      url: `${basePvtURL}/downloads`,
    },
  },
};
