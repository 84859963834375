import React from "react";
import { Box, CssBaseline, Typography, useTheme } from "@mui/material";
import PageWrapper from "../../components/wrapper";
import faqs from "../../shared/fixtures/faqs.json";
import "./styles.css";

const FAQs: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Box display="flex" flexDirection="column">
      <CssBaseline />
      <PageWrapper minHeight="20vh" backgroundOpacity="0.4">
        <Typography variant="body2" color={palette.success.light}>
          FAQS
        </Typography>
        <Typography className="tag-line2">
          Frequently Asked Questions
        </Typography>
      </PageWrapper>
      <Box className="faq-main-box">
        {faqs.map((item, i) => (
          <Box key={i}>
            <Typography className="faq-content-title">
              {item.question}
            </Typography>
            {item.answers.map((ans, i) => (
              <React.Fragment key={i}>
                <p
                  key={i}
                  className="faq-content-body"
                  dangerouslySetInnerHTML={{ __html: ans }}
                />
              </React.Fragment>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FAQs;
