import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Typography variant="h2" color="#3e63dd" align="center" gutterBottom>
        Page Not Found
      </Typography>
      <Typography
        variant="h5"
        color="textSecondary"
        align="center"
        gutterBottom
      >
        Oops! The page you are looking for does not exist.
      </Typography>
      <Button component={Link} to="/" variant="text" color="primary">
        Go Back Home
      </Button>
    </Box>
  );
};

export default NotFound;
