import { Box } from "@mui/material";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { isNumber } from "@mui/x-data-grid/internals";
import StatusTooltip from "../../components/tooltip";

const DataGridColumns = (): Record<string, GridColDef[]> => {
  return {
    builder_page: [
      {
        field: "market_name",
        headerName: "Market",
        flex: 0.1,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "community_name",
        headerName: "Community",
        flex: 0.2,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "job_number",
        headerName: "Job No.",
        flex: 0.15,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "county_name",
        headerName: "County",
        flex: 0.15,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "street_number",
        headerName: "Street No.",
        flex: 0.05,
        minWidth: 120,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "street_name",
        headerName: "Street Name",
        flex: 0.2,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "legal_lot",
        headerName: "Lot",
        width: 25,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "legal_block",
        headerName: "Block",
        width: 55,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "legal_section",
        headerName: "Section",
        width: 75,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "account_number",
        headerName: "Account No.",
        flex: 0.15,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "reference_number",
        headerName: "Reference No.",
        flex: 0.2,
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "value_status",
        headerName: "Status",
        width: 90,
        renderHeader: (params: GridColumnHeaderParams) => (
          <Box display="flex" alignItems="center" justifyContent="center">
            <StatusTooltip />
            <span>{params.colDef.headerName}</span>
          </Box>
        ),
        valueFormatter: ({ value }) => value ?? "-",
      },
      {
        field: "value_projected",
        headerName: "Projected Value",
        flex: 0.15,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `$${Math.round(value).toLocaleString()}` : "-",
        align: "right",
        headerAlign: "right",
        cellClassName: "emphasis",
      },
    ],
    dashboard_metrics: [
      {
        field: "communityName",
        headerName: "Community",
        minWidth: 250,
        flex: 0.2,
        filterable: false,
      },
      {
        field: "county",
        headerName: "County",
        minWidth: 200,
        flex: 0.2,
        filterable: false,
      },
      {
        field: "count",
        headerName: "# of Accounts",
        width: 100,
        filterable: false,
        align: "right",
        valueFormatter: ({ value }) =>
          isNumber(value) ? value.toLocaleString() : "-",
        headerAlign: "right",
      },
      {
        field: "taxableValue",
        headerName: "Est. Taxable Value",
        minWidth: 150,
        flex: 0.1,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `$${Math.round(value).toLocaleString()}` : "-",
        filterable: false,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "taxRate",
        headerName: "Tax Rate",
        minWidth: 90,
        valueGetter: (params) => Number(params.row.taxRate) * 100,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `${value.toFixed(4)}%` : "-",
        filterable: false,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "projectedTaxesDue",
        headerName: "Projected Tax Due",
        minWidth: 150,
        flex: 0.1,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `$${Math.round(value).toLocaleString()}` : "-",
        filterable: false,
        align: "right",
        headerAlign: "right",
        cellClassName: "emphasis",
      },
    ],
    estimated_value: [
      {
        field: "communityName",
        headerName: "Community",
        flex: 0.15,
        minWidth: 130,
        filterable: false,
      },
      {
        field: "jobNumber",
        headerName: "Job Number",
        flex: 0.125,
        minWidth: 100,
        filterable: false,
      },
      {
        field: "streetAddress",
        headerName: "Address",
        flex: 0.225,
        minWidth: 150,
        filterable: false,
      },
      {
        field: "accountNumber",
        headerName: "Account No.",
        flex: 0.125,
        minWidth: 200,
        filterable: false,
      },
      {
        field: "referenceNumber",
        headerName: "CAD Reference No.",
        flex: 0.125,
        minWidth: 80,
        valueFormatter: ({ value }) => value ?? "-",
        filterable: false,
      },
      {
        field: "valueStatus",
        headerName: "Status",
        width: 90,
        renderHeader: (params: GridColumnHeaderParams) => (
          <Box display="flex" alignItems="center" justifyContent="center">
            <StatusTooltip />
            <span>{params.colDef.headerName}</span>
          </Box>
        ),
        filterable: false,
      },
      {
        field: "taxRate",
        headerName: "Tax Rate",
        width: 90,
        valueGetter: (params) => Number(params.row.taxRate) * 100,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `${value.toFixed(4)}%` : "-",
        align: "right",
        headerAlign: "right",
        filterable: false,
      },
      {
        field: "valueNoticed",
        headerName: "Noticed Value",
        flex: 0.1,
        minWidth: 100,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `$${Math.round(value).toLocaleString()}` : "-",
        align: "right",
        headerAlign: "right",
        filterable: false,
      },
      {
        field: "valueProjected",
        headerName: "Projected Value",
        flex: 0.1,
        minWidth: 100,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `$${Math.round(value).toLocaleString()}` : "-",
        align: "right",
        headerAlign: "right",
        filterable: false,
        cellClassName: "emphasis",
      },
    ],

    est_taxes_due_detail: [
      {
        field: "communityName",
        headerName: "Community",
        flex: 0.15,
        minWidth: 130,
        filterable: false,
      },
      {
        field: "jobNumber",
        headerName: "Job Number",
        flex: 0.125,
        minWidth: 100,
        filterable: false,
      },
      {
        field: "streetAddress",
        headerName: "Address",
        flex: 0.225,
        minWidth: 150,
        filterable: false,
      },
      {
        field: "accountNumber",
        headerName: "Account No.",
        flex: 0.125,
        minWidth: 200,
        filterable: false,
      },

      {
        field: "referenceNumber",
        headerName: "CAD Reference No.",
        flex: 0.125,
        minWidth: 80,
        valueFormatter: ({ value }) => value ?? "-",
        filterable: false,
      },
      {
        field: "valueStatus",
        headerName: "Status",
        width: 90,
        renderHeader: (params: GridColumnHeaderParams) => (
          <Box display="flex" alignItems="center" justifyContent="center">
            <StatusTooltip />
            <span>{params.colDef.headerName}</span>
          </Box>
        ),
        filterable: false,
      },
      {
        field: "taxableValue",
        headerName: "Taxable Value",
        flex: 0.1,
        minWidth: 100,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `$${Math.round(value).toLocaleString()}` : "-",
        filterable: false,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "taxRate",
        headerName: "Tax Rate",
        minWidth: 90,
        valueGetter: (params) => Number(params.row.taxRate) * 100,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `${value.toFixed(4)}%` : "-",
        filterable: false,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "projectedTaxesDue",
        headerName: "Projected Tax Amt.",
        flex: 0.1,
        minWidth: 150,
        valueFormatter: ({ value }) =>
          isNumber(value)
            ? `$${value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : "-",
        filterable: false,
        align: "right",
        headerAlign: "right",
        cellClassName: "emphasis",
      },
    ],
    est_taxes_savings: [
      {
        field: "communityName",
        headerName: "Community",
        flex: 0.15,
        minWidth: 130,
        filterable: false,
      },
      {
        field: "jobNumber",
        headerName: "Job Number",
        flex: 0.125,
        minWidth: 90,
        filterable: false,
      },
      {
        field: "streetAddress",
        headerName: "Address",
        flex: 0.225,
        minWidth: 150,
        filterable: false,
      },
      {
        field: "accountNumber",
        headerName: "Account No.",
        flex: 0.125,
        minWidth: 200,
        filterable: false,
      },
      {
        field: "referenceNumber",
        headerName: "CAD Reference No.",
        flex: 0.125,
        minWidth: 70,
        valueFormatter: ({ value }) => value ?? "-",
        filterable: false,
      },
      {
        field: "valueStatus",
        headerName: "Status",
        width: 90,
        renderHeader: (params: GridColumnHeaderParams) => (
          <Box display="flex" alignItems="center" justifyContent="center">
            <StatusTooltip />
            <span>{params.colDef.headerName}</span>
          </Box>
        ),
        filterable: false,
      },
      {
        field: "taxRate",
        headerName: "Tax Rate",
        width: 90,
        valueGetter: (params) => Number(params.row.taxRate) * 100,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `${value.toFixed(4)}%` : "-",
        filterable: false,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "taxableValue",
        headerName: "Taxable Value",
        flex: 0.1,
        minWidth: 125,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `$${Math.round(value).toLocaleString()}` : "-",
        filterable: false,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "valueChange",
        headerName: "Value Chg.",
        flex: 0.1,
        minWidth: 100,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `$${Math.round(value).toLocaleString()}` : "-",
        filterable: false,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "taxSavingsProrated",
        headerName: "Tax Savings",
        flex: 0.1,
        minWidth: 125,
        valueFormatter: ({ value }) =>
          isNumber(value) ? `$${Math.round(value).toLocaleString()}` : "-",
        filterable: false,
        align: "right",
        headerAlign: "right",
        cellClassName: "emphasis",
      },
    ],
  };
};

export default DataGridColumns;
