import React from "react";
import { Box, CssBaseline, Divider, Typography, useTheme } from "@mui/material";
import PageWrapper from "../../components/wrapper";
import AboutUsItems from "../../shared/fixtures/AboutUsItems";
import TeamCard from "../../components/teamCard";
import "./styles.css";

const About: React.FC = () => {
  const { spacing, typography, palette } = useTheme();
  const { titles, descriptions, features, team } = AboutUsItems();
  return (
    <Box display="flex" flexDirection="column">
      <CssBaseline />
      <PageWrapper minHeight="20vh" backgroundOpacity="0.4">
        <Typography variant="body2" color={palette.success.light}>
          ABOUT US
        </Typography>
        <Typography className="tag-line2">Why Ad Valorem Advisors?</Typography>
      </PageWrapper>
      <Box className="about-content-box">
        <Box className="about-content">
          <Box className="section-left">
            <Typography
              fontWeight={typography.fontWeightBold}
              fontSize={spacing(5)}
            >
              {titles.section1[0]} <br />
              <span
                style={{
                  color: palette.success.contrastText,
                }}
              >
                {titles.section1[1]}
              </span>
            </Typography>
          </Box>
          <Box className="section-right">
            <Typography className="about-section-description" variant="body2">
              {descriptions}
            </Typography>
            {/* <Box className="section-right-sub-box">
              {features.map((item, i) => (
                <Box key={i} className="about-section-container">
                  <Box
                    className={
                      i == 1
                        ? "about-section-sub-box-2"
                        : "about-section-sub-box"
                    }
                  >
                    <Typography className="about-section-description-title">
                      {item.title}
                    </Typography>
                    <Typography
                      className="about-section-description-body"
                      variant="body2"
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box> */}
          </Box>
        </Box>
        <Divider />
        <Box className="about-content">
          <Box className="section-left">
            <Typography
              fontWeight={typography.fontWeightBold}
              fontSize={spacing(5)}
            >
              {titles.section2[0]} <br />
              <span
                style={{
                  color: palette.success.contrastText,
                }}
              >
                {titles.section2[1]}
              </span>
            </Typography>
          </Box>
          <Box className="section2-right">
            {team.map((item, i) => (
              <TeamCard
                key={i}
                // customer requested no images ATX-232
                image={undefined}
                name={item.name}
                about={item.about}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
