// https://overreacted.io/making-setinterval-declarative-with-react-hooks/

import { useEffect, useRef } from "react";

export function useInterval<T extends Function>(callback: T, delay: number | undefined | null) {
    const savedCallback = useRef<T>();
   
    useEffect(() => {
      savedCallback.current = callback;
    });
   
    useEffect(() => {
      async function tick() {
        await savedCallback.current?.();
      }
   
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
  
    }, [delay]);
  }
