import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  useTheme,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import { ReactComponent as Logo } from "../../shared/assets/icons/Logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import navbarItems from "../../shared/fixtures/navbarItems.json";
import MenuIcon from "@mui/icons-material/Menu";
import "./styles.css";
import { cognitoLoginURL } from "../../config";

const Navbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { spacing, palette, typography } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleMenuItemClick = (link: string) => {
    navigate(link);
    handleMenuClose();
  };

  const handleLoginBtn = () => (window.location.href = cognitoLoginURL);

  return (
    <AppBar position="static" color="default">
      <Toolbar className="navbar">
        <Typography
          className="navbar-logo"
          variant="h6"
          component="div"
          flexGrow={1}
        >
          <Logo onClick={() => navigate("/")} />
        </Typography>
        <Box className="navbar-items">
          <Button
            variant="text"
            onClick={handleMenuOpen}
            startIcon={<MenuIcon />}
            sx={{ display: { xs: "block", md: "none" } }}
          />
          {navbarItems.map((item) => (
            <Button
              key={item.id}
              variant="text"
              className={item.id === "login" ? "login-btn" : ""}
              sx={{
                fontWeight: typography.fontWeightBold,
                color:
                  pathname === item.link
                    ? palette.text.secondary
                    : palette.text.primary,
                borderBottom:
                  pathname === item.link
                    ? `${spacing(0.3)} solid ${palette.success.light}`
                    : "none",
                display: { xs: "none", md: "block" },
              }}
              onClick={() => {
                item.id === "login"
                  ? handleLoginBtn()
                  : handleMenuItemClick(item.link);
              }}
            >
              {item.title}
            </Button>
          ))}
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          keepMounted
        >
          {navbarItems.map((item) => (
            <MenuItem
              className={item.id === "login" ? "login-btn" : ""}
              key={item.id}
              onClick={() => {
                item.id === "login"
                  ? handleLoginBtn()
                  : handleMenuItemClick(item.link);
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
