import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCustomContext } from "../../providers/ContextProvider";
import { ReactComponent as DownloadIcon } from "../../shared/assets/icons/download.svg";
import SidebarItems from "../navigation/SidebarItems";
import "./styles.css";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DashboardNavBar(props: any) {
  const { drawerWidth, isLoading, requestDownloadAll } = props;
  const [activePage, setActivePage] = useState("All");
  const menuItems = SidebarItems;
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const { selectedBuilderMarket } = useCustomContext();
  const { builderName, marketName } = selectedBuilderMarket ?? {};

  useEffect(() => {
    switch (location.pathname) {
      case menuItems[0].link:
        setActivePage("Dashboard");
        break;
      case menuItems[1].link:
        setActivePage("Estimated Property Value");
        break;
      case menuItems[2].link:
        setActivePage("Estimated Taxes Due");
        break;
      case menuItems[3].link:
        setActivePage("Estimated Tax Savings");
        break;
    }
  }, [location.pathname]);

  return (
    <Box
      id="navigation-tabs"
      className="dashboard-navbar-container"
      width={`calc(100vw - 300px)`}
      ml={`${drawerWidth}px`}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Typography className="dashboard-page-title">
          {builderName} - {marketName}: {activePage}
        </Typography>
        <Button
          className={isLoading ? "disabled-btn-box" : "download-btn-box"}
          onClick={requestDownloadAll}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <DownloadIcon />
          )}
          <p>Download All Data</p>
        </Button>
      </Toolbar>
      <Toolbar>
        <Tabs
          value={menuItems.findIndex((item) => location.pathname === item.link)}
        >
          {menuItems.map((item, index) => (
            <Tab
              key={index}
              label={
                <div className="item-nav-bar-tab">
                  {item.icon}
                  <span>{item.text}</span>
                </div>
              }
              onClick={() => navigate(item.link)}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Toolbar>
    </Box>
  );
}
