import { Box } from "@mui/material";
import Chart, { ChartConfiguration } from "chart.js/auto";
import React, { memo, useEffect, useRef } from "react";
import "./styles.css";

interface PieChartProps {
  data: {
    values: number[];
    colors: string[];
    labels: string[];
  };
  activeIndex?: number | null;
  setActiveIndex?: any;
}

const PieChart: React.FC<PieChartProps> = memo(
  ({ data, activeIndex, setActiveIndex }) => {
    const { labels, values, colors } = data;

    const valueCount = values.map((item: any) => item.count);
    const isNoneValue = valueCount.every((item) => item === 0);

    const chartContainerRef = useRef<HTMLCanvasElement>(null);
    const chartInstanceRef = useRef<Chart | null>(null);

    const footer = (tooltipItems: any) => {
      let detail = tooltipItems[0].dataset?.detail;
      return `tax: $${Math.round(
        detail?.projectedTaxesDue
      ).toLocaleString()}`;
    };

    useEffect(() => {
      if (!isNoneValue) {
        let myChart: Chart | null = null;

        if (chartContainerRef.current) {
          const ctx = chartContainerRef.current.getContext("2d");
          if (ctx) {
            const chartConfig: ChartConfiguration<any> = {
              type: "pie",
              data: {
                labels: labels,
                datasets: [
                  {
                    data: valueCount,
                    detail: activeIndex != null && values[activeIndex - 1],
                    backgroundColor: colors,
                  },
                ],
              },
              options: {
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      footer: footer,
                    },
                  },
                },
                animation: false,
                onHover: (
                  event: MouseEvent,
                  activeElements: any[],
                  chart: Chart
                ) => {
                  if (chart && activeElements && activeElements.length > 0) {
                    setActiveIndex(activeElements[0].index + 1);
                  }
                },
              },
            };

            myChart = new Chart(ctx, chartConfig);
            chartInstanceRef.current = myChart;
          }
        }

        return () => {
          if (myChart) {
            myChart.destroy();
          }
        };
      }
    }, [data]);

    useEffect(() => {
      if (!isNoneValue) {
        if (
          activeIndex != null &&
          activeIndex >= 0 &&
          chartInstanceRef.current
        ) {
          const backgroundColors = chartInstanceRef.current.data.datasets[0]
            ?.backgroundColor as [];

          const updatedBackgroundColors = backgroundColors.map(
            (color: string, index: number) => {
              if (
                activeIndex !== null &&
                index + 1 !== activeIndex &&
                activeIndex !== 0
              ) {
                return "#dcdcdc";
              }
              return color;
            }
          );

          chartInstanceRef.current.data.datasets[0].backgroundColor =
            updatedBackgroundColors;
          chartInstanceRef.current.update();
        }
      }
    }, [activeIndex]);

    return (
      <Box className="chart-container">
        {isNoneValue ? <p>No Data</p> : <canvas ref={chartContainerRef} />}
      </Box>
    );
  }
);

export default PieChart;
