import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import { useState } from "react";
import ErrorMessage from "../../components/errorMessage";
import NoFilterSelected, {
  NoDataFound,
} from "../../components/noFilterSelected";
import {
  QuickSearchToolbar,
  StripedDataGrid,
} from "../../components/stripedDataGrid";
import { useCustomContext } from "../../providers/ContextProvider";
import DataGridColumns from "../../shared/fixtures/DataGridColumns";
import { useEstTaxesDue } from "../../shared/hooks/private";
import { useFilterPropertiesBySearch } from "../../shared/hooks/private/useFilterPropertiesBySearch";
import { useSearchIndex } from "../../shared/hooks/private/useSearchIndex";

const EstTaxDue = () => {
  const [selectedRows, setSelectedRows] = useState<GridRowId[] | []>([]);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const [searchedData, setSearchedData] = useState({
    category: "",
    value: "",
    sort: "",
  });

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const { filterValue, selectedBuilderMarket } = useCustomContext();
  const { builderName, marketName } = selectedBuilderMarket!;

  const communities = filterValue.map((item) => item.value);
  const queryParams = communities
    .map((community) => `community=${encodeURIComponent(community)}`)
    .join("&");

  const {
    data: estTaxDueDetailData,
    isLoading: isEstTaxLoading,
    error: estTaxDueError,
  } = useEstTaxesDue(builderName, marketName, queryParams);

  const columns = DataGridColumns().est_taxes_due_detail;

  const properties = estTaxDueDetailData?.properties || [];

  const filteredData = properties?.filter((item: any) =>
    selectedRows.includes(item?.jobNumber as never)
  );

  const finalizedFilteredData = useFilterPropertiesBySearch(
    properties,
    searchedData
  );

  const handleFilterClick = () => setIsFilterOpen(!isFilterOpen);

  const searchIndex = useSearchIndex(properties);

  const dataGridRows =
    filterValue.length > 0
      ? isFilterOpen
        ? filteredData
        : searchedData
        ? finalizedFilteredData
        : properties
      : [];

  return (
    <Box className="dashboard-datagrid-box">
      {estTaxDueError ? <ErrorMessage error={estTaxDueError as Error} /> : null}
      {dataGridRows.length === 0 &&
      filterValue.length > 0 &&
      !isEstTaxLoading ? (
        <NoDataFound />
      ) : (
        <>
          <QuickSearchToolbar
            selectedRows={selectedRows}
            isFilterOpen={isFilterOpen}
            handleFilterClick={handleFilterClick}
            searchIndex={searchIndex}
            setSearchedData={setSearchedData}
            downloadType="estimatedTax"
          />
          <StripedDataGrid
            className="dashboard-datagrid"
            sx={{
              height:
                filterValue.length < 1 || isEstTaxLoading ? "500px" : null,
            }}
            rowHeight={42}
            getRowId={(params) => params.jobNumber}
            rows={dataGridRows}
            columns={columns}
            checkboxSelection
            disableRowSelectionOnClick
            pagination
            pageSizeOptions={[10, 25, 50, 100, 250, 500]}
            loading={isEstTaxLoading}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            onRowSelectionModelChange={(selection) => {
              if (selection.length > 0) {
                setSelectedRows(selection);
              } else {
                setSelectedRows([]);
                setIsFilterOpen(false);
              }
            }}
            slots={{
              noRowsOverlay: NoFilterSelected,
            }}
          />
        </>
      )}
    </Box>
  );
};

export default EstTaxDue;
