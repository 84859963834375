import { ArrowDropDown, Logout } from "@mui/icons-material";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { cognitoLogoutURL } from "../../config";
import {
  BuilderMarket,
  FilterType,
  useCustomContext,
} from "../../providers/ContextProvider";
import { ReactComponent as Logo } from "../../shared/assets/icons/Logo.svg";
import { useDownloadAsync } from "../../shared/hooks/private";
import DashboardNavBar from "../dashboardNavbar";
import {
  DownloadConfirmationDialog,
  DownloadErrorInfoDialog,
  LogoutConfirmationDialog,
} from "../dialogs";
import "./styles.css";

import dashboardTourSteps from "../../shared/fixtures/dashboardTourSteps.json";
import selectBuilderMarketTourSteps from "../../shared/fixtures/selectBuilderMarketTourSteps.json";
import TourGuide from "../tourGuide";
import CommunitySelection from "./CommunitySelection";

const drawerWidth = 280;

interface NavigationBarProps {
  hasDashboardNavBar?: boolean;
}
type PayloadDataType = {
  downloadType: string;
  builderName: string;
  marketName: string;
  communities?: string[];
};
const NavigationBar = ({ hasDashboardNavBar = true }: NavigationBarProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const {
    filterValue,
    setFilterValue,
    isDownloadLoading,
    setIsDownloadLoading,
    connectWebSocketAndListen,
    selectedBuilderMarket,
    setSelectedBuilderMarket,
    builderMarkets,
  } = useCustomContext();

  const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const { builderName, marketName } = selectedBuilderMarket ?? {};
  const userName = user?.name || "";

  const {
    isLoading: isDownloadAsyncLoading,
    mutate: requestAsyncData,
    error: downloadAsyncError,
  } = useDownloadAsync();

  const payloadData: PayloadDataType = {
    downloadType: "all",
    builderName: builderName!,
    marketName: marketName!,
    communities: [],
  };

  useEffect(() => {
    if (isDownloadLoading.length > 0) {
      const isRequestLoading = isDownloadLoading.some(
        (item) => item.type === payloadData.downloadType
      );
      setIsBtnLoading(isRequestLoading);
    } else {
      setIsBtnLoading(false);
    }
  }, [isDownloadLoading]);

  useEffect(() => {
    if (downloadAsyncError) {
      setOpen(true);
      setIsDownloadLoading(
        isDownloadLoading.filter(
          (item) => item.type !== payloadData.downloadType
        )
      );
    }
  }, [downloadAsyncError]);

  const handleLogoutBtn = () => {
    setLogoutDialogOpen(true);
    setAnchorEl(null);
  };

  const handleCloseLogoutDialog = () => setLogoutDialogOpen(false);

  const handleConfirmLogout = () => {
    setLogoutDialogOpen(false);
    window.location.href = cognitoLogoutURL;
    localStorage.removeItem("user");
    localStorage.removeItem("sessionStartedAt");
  };

  const requestDownloadAll = (payloadData: PayloadDataType) => {
    // make a fresh new websocket connection to avoid all risk of a stale connection
    const newWebSocketConnection = connectWebSocketAndListen();
    // then make the download request with the new connection
    newWebSocketConnection ? requestAsyncData(payloadData) : setOpen(true);
  };

  const selectedCommunities = useMemo(
    () =>
      (filterValue.length > 0 && filterValue?.map((item) => item.value)) || [],
    [filterValue]
  );

  const handleDownloadActions = () => {
    if (selectedCommunities.length > 0) {
      setDownloadDialogOpen(true);
    } else {
      requestDownloadAll(payloadData);
    }
  };
  const handleDownloadConfirmation = (isSelected: boolean) => {
    if (isSelected) {
      payloadData["communities"] = selectedCommunities;
    }
    requestDownloadAll(payloadData);
    setDownloadDialogOpen(false);
  };

  const error = downloadAsyncError;
  const isLoading =
    isDownloadAsyncLoading || isBtnLoading || isCategoriesLoading;

  const handleBuilderMarketChange = (
    _event: SyntheticEvent<Element, Event>,
    value: BuilderMarket | null
  ) => {
    setSelectedBuilderMarket(value);
  };

  const handleCommunitiesChange = (newValues: FilterType[]) => {
    setFilterValue(newValues);
  };

  const dashboardTourStepsMemoized = useMemo(() => {
    if (builderMarkets.length) {
      if (builderMarkets.length === 1) {
        return dashboardTourSteps.slice(1);
      }
      return dashboardTourSteps;
    }
    return null;
  }, [builderMarkets]);

  const selectBuilderMarketTourStepsMemoized = useMemo(() => {
    if (builderMarkets.length) {
      if (builderMarkets.length === 1) {
        return selectBuilderMarketTourSteps.slice(0, 1);
      }
      return selectBuilderMarketTourSteps;
    }
    return null;
  }, [builderMarkets]);

  const setIsCategoriesLoadingCallback = useCallback(
    (value: boolean) => {
      setIsCategoriesLoading(value);
    },
    [setIsCategoriesLoading]
  );
  return (
    <>
      <AppBar
        id="navbar-settings"
        position="fixed"
        color="default"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box className="navbar-profile-container">
            <Button
              color="inherit"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="body2">{userName}</Typography>
                <Avatar alt={userName} />
                <ArrowDropDown />
              </Box>
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              sx={{ zIndex: 1302 }}
            >
              <MenuItem onClick={handleLogoutBtn}>
                <IconButton color="inherit">
                  <Logout />
                </IconButton>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
        <LogoutConfirmationDialog
          logoutDialogOpen={logoutDialogOpen}
          handleCloseLogoutDialog={handleCloseLogoutDialog}
          handleConfirmLogout={handleConfirmLogout}
        />
        {selectedCommunities.length > 0 ? (
          <DownloadConfirmationDialog
            downloadDialogOpen={downloadDialogOpen}
            setDownloadDialogOpen={setDownloadDialogOpen}
            selectedCommunities={selectedCommunities}
            handleDownloadConfirmation={handleDownloadConfirmation}
            isDownloadLoading={isLoading}
          />
        ) : null}
      </AppBar>
      {hasDashboardNavBar && (
        <DashboardNavBar
          drawerWidth={drawerWidth}
          isLoading={isLoading}
          requestDownloadAll={handleDownloadActions}
        />
      )}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <DownloadErrorInfoDialog
          open={open}
          setOpen={setOpen}
          error={error as Error}
        />
        <Toolbar>
          <Logo className="sidebar-logo" />
        </Toolbar>
        <List id="builder-market-select-box">
          <ListItem>
            <ListItemText
              className="sidebar-title"
              primary="Builder - Market"
            />
          </ListItem>
          <ListItem
            sx={{
              textAlign: "center",
            }}
          >
            {builderMarkets.length === 1 && (
              <ListItemText primary={`${builderName} - ${marketName}`} />
            )}
            {builderMarkets.length > 1 && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={handleBuilderMarketChange}
                options={builderMarkets}
                value={selectedBuilderMarket}
                isOptionEqualToValue={(opt, val) => opt.label === val.label}
                fullWidth
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Builder-Market"
                    placeholder="type to select"
                  />
                )}
              />
            )}
          </ListItem>
        </List>
        <List id="communities">
          {!!selectedBuilderMarket && (
            <ListItem>
              <ListItemText className="sidebar-title" primary="Filter" />
            </ListItem>
          )}
          {!!selectedBuilderMarket && (
            <ListItem id="community-filter-autocomplete">
              <CommunitySelection
                onChange={handleCommunitiesChange}
                builderMarket={selectedBuilderMarket}
                selectedCommunities={filterValue}
                setIsCategoriesLoading={setIsCategoriesLoadingCallback}
              />
            </ListItem>
          )}
        </List>
        {hasDashboardNavBar &&
          !!dashboardTourStepsMemoized &&
          selectedBuilderMarket && (
            <TourGuide
              tourSteps={dashboardTourStepsMemoized}
              name="tourGuideStep"
            />
          )}

        {!hasDashboardNavBar && !!selectBuilderMarketTourStepsMemoized && (
          <TourGuide
            tourSteps={selectBuilderMarketTourStepsMemoized}
            name="builderMarket-tourGuideStep"
          />
        )}
      </Drawer>
    </>
  );
};

export default NavigationBar;
