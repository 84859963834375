import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

import { BuilderStripedDataGrid } from "../../components/stripedDataGrid";
import VirtualizeAutoComplete from "../../components/virtualizeAutoComplete";
import PageWrapper from "../../components/wrapper";
import { ReactComponent as Location } from "../../shared/assets/icons/map-pin-light.svg";
import { ReactComponent as Search } from "../../shared/assets/icons/search-solid.svg";
import DataGridColumns from "../../shared/fixtures/DataGridColumns";
import {
  useMarketListAll,
  useProjectedProperty,
  useSearchCategories,
} from "../../shared/hooks/public";
import { convertCategoryFormat } from "../../shared/utils";
import "./styles.css";

const Builder = () => {
  const [selectedCommunity, setSelectedCommunity] = useState<any | null>(null);
  const [selectedMarket, setSelectedMarket] = useState<any | null>(null);
  const [propertyList, setPropertyList] = useState<any | null>([]);
  const [selectedCategory, setSelectedCategory] = useState<any | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [previousSearch, setPreviousSearch] = useState<string>('');
  const [searchIndex, setSearchIndex] = useState({
    communities: [],
    streetNames: [],
    jobNumbers: [],
    accountNumbers: [] as string[],
    streetAddresses: [],
  });
  const [isCategoriesLoading, setCategoriesLoading] = useState(false);
  const [isPropertyLoading, setPropertyLoading] = useState(false);
  const { palette } = useTheme();
  const { data: marketData, isLoading: isMarketLoading } = useMarketListAll();

  const { data: categoriesData, refetch: getCategoriesData } =
    useSearchCategories(selectedMarket);

  const { data: propertyData, refetch: getPropertyData } = useProjectedProperty(
    selectedMarket,
    selectedCategory?.category,
    selectedCategory?.value
  );

  const columns = DataGridColumns().builder_page;

  useEffect(() => {
    selectedMarket && getCategoriesData();
  }, [selectedMarket]);

  // submit the form on any change to selectedCategory
  useEffect(() => {
    !!selectedCategory && handleSearch();
  }, [selectedCategory]);

  // clear the property list when there is no selected market OR no selected query
  useEffect(() => {
    (!selectedMarket || !selectedCategory) && setPropertyList([]);
  }, [selectedMarket, selectedCategory]);

  useEffect(() => {
    setSelectedMarket(null);
    setSelectedCommunity(null);
  }, []);

  const params =
    propertyList?.length >= 0 && propertyData?.properties
      ? {
          minHeight: "20vh",
          backgroundOpacity: "0.4",
        }
      : {};

  useEffect(() => {
    if (categoriesData?.searchIndexUrl) {
      setCategoriesLoading(true);
      axios
        .get(categoriesData.searchIndexUrl)
        .then((res) => {
          setSearchIndex(res.data);
          setCategoriesLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setCategoriesLoading(false);
        });
    }
  }, [categoriesData]);

  const options: Array<{category: string; sort: string; value: string;}> = [
    ...(searchIndex?.communities?.map((item: string) => ({
      category: "Community",
      sort: `1-${item}`,
      value: item,
    })) ?? []),
    ...(searchIndex?.streetNames
      .filter((item) => item !== "")
      ?.map((item: any) => ({
        category: "Street Name",
        sort: `2-${item}`,
        value: item,
      })) ?? []),
    ...(searchIndex?.streetAddresses?.map((item: string) => ({
        category: "Address",
        sort: `3-${item}`,
        value: item,
      })) ?? []),
    ...(searchIndex?.jobNumbers?.map((item: string) => ({
      category: "Job Number",
      sort: `4-${item}`,
      value: item,
    })) ?? []),
    ...(searchIndex?.accountNumbers?.map((item: string) => ({
      category: "Account Number",
      sort: `5-${item}`,
      value: item,
    })) ?? []),
  ];

  const handleSearch = () => {
    setPropertyLoading(true);
    getPropertyData()
      .then((res) => {
        setPropertyLoading(false);
        setPreviousSearch(selectedCategory.value);
        setPropertyList(res?.data.properties)
      })
      .catch(() => {
        setPropertyLoading(false);
        setPreviousSearch('ERROR');
        setPropertyList([]);
      });
  };

  const MarketAutoComplete = (
    <Autocomplete
      sx={{ width: "100%" }}
      className="location"
      options={marketData?.markets || []}
      getOptionLabel={(option: string) => option}
      onChange={(event, newValue) => {
        setSelectedMarket(newValue);
        setSelectedCategory(null);
        setInputValue('');
      }}
      loading={isMarketLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder="Market"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );

  const filterOptions = createFilterOptions({
     stringify: (option: {category: string; sort: string; value: string;}) => option.value, 
  });

  const getInputFilteredOptions = () => {
    const sorted = [
      (!!inputValue && inputValue.length > 2|| selectedCategory?.value)
        ? {
          category: '🔍 Search All Categories for',
          sort: '0-Global Search',
          value: inputValue,
        }
        : null,
      ...options.sort((a, b) => -b.sort.localeCompare(a.sort))
    ].filter((option) => !!option);
    // @ts-ignore
    const filtered = filterOptions(sorted, {
      inputValue,
      getOptionLabel: (option) => option.value,
    });
    return filtered;
  };

  const SearchAutoComplete = (
    <VirtualizeAutoComplete
      id="grouped-demo"
      sx={{ width: "100%" }}
      className="community"
      options={getInputFilteredOptions()}
      loading={isCategoriesLoading}
      key={selectedMarket}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="standard"
          className="search-filter-input"
          placeholder="search by address, street, or community"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
          onBlur={() => {
            if (!selectedCategory && !!inputValue && inputValue.length > 2) {
              setSelectedCategory({
                category: convertCategoryFormat('🔍 Search All Categories for'),
                sort: '0-Global Search',
                value: inputValue,
              })
            }
          }}
        />
      )}
      getOptionLabel={(option: any) => option.value}
      onChange={(event: any, value: any) => {
        if (!!value) {
          setSelectedCategory({
            category: convertCategoryFormat(value?.category),
            value: value?.value as string,
            sort: value?.sort as string,
          })
        } else {
          setSelectedCategory(undefined);
          setPreviousSearch('');
        }
      }}
      onInputChange={(event: any, value: any) => {
        setInputValue(value);
      }}
      selectOnFocus={false}
      autoSelect={selectedCategory === null ? true : false}
      autoHighlight
      isOptionEqualToValue={(option: any, value: any) =>
        option.value === value.value || inputValue
      }
      disabled={!selectedMarket}
    />
  );

  const ErrorMessage = () => {
    switch (previousSearch) {
      case 'ERROR':
        return (
          <span>
            Something went wrong.<br/>
            Please try your search again.
          </span>
        );
      case '':
        return (
          <span>
            Please select a new search.
          </span>
        );
      default:
        return (
          <span>
            No results found for &ldquo;<strong style={{color: "rgba(0,0,0,0.87)"}}>{previousSearch}</strong>&rdquo;.<br/>
            Please try your search again.
          </span>
        );
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <CssBaseline />
      <PageWrapper {...params}>
        <Box className="main-body">
          <Box display={propertyList?.length >= 0 && propertyData?.properties ? "none" : ""}>
            <Typography className="tag-line2">
              Value Estimate Search:
            </Typography>
            <Typography className="subtitle">
              To find properties, select a market, then type to select an address, street, 
              community, job number, or account number.
            </Typography>
          </Box>
          <Box className="search-box">
            <Location />
            {MarketAutoComplete}
            <Search />
            {SearchAutoComplete}
            <Button
              className="search-button"
              color="secondary"
              variant="contained"
              disabled={!(selectedMarket && selectedCategory && isPropertyLoading)}
            >
              {isPropertyLoading ? (
                <CircularProgress size={24} color="success" />
              ) : (
                "Search"
              )}
            </Button>
          </Box>
          <Box
            className="search-box-mobile"
            sx={{ width: "60vw", margin: "auto" }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="center">
                  <Location />
                  {MarketAutoComplete}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="center">
                  <Search />
                  {SearchAutoComplete}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Button
                  className="search-button-mobile"
                  color="secondary"
                  variant="contained"
                  disabled={!(selectedMarket && selectedCategory && isPropertyLoading)}
                  fullWidth
                >
                  {isPropertyLoading ? (
                    <CircularProgress size={24} color="success" />
                  ) : (
                    "Search"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </PageWrapper>
      {propertyList?.length > 0 && propertyData?.properties && (
        <Box className="markets-data-grid-box">
          <BuilderStripedDataGrid
            className="markets-data-grid"
            rowHeight={36}
            getRowId={(option) => option.job_number}
            rows={propertyList}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            disableRowSelectionOnClick
            pagination
            pageSizeOptions={[10, 25, 50, 100, 250, 500]}
            loading={isPropertyLoading}
          />
        </Box>
      )}
      {propertyList?.length === 0 && propertyData?.properties && (
        <Box 
          className="markets-data-grid-box"
          sx={{
            display: "flex",
            flex: "1 1 100%",
          }}>
          <Box
            sx={{
              textAlign: "Center",
              flex: "0 0 auto",
              margin: "auto",
              fontSize: "20px",
              color: "rgba(0,0,0,0.56)"
            }}>
            <ErrorMessage/>
          </Box>
        </Box>
      )
      }
    </Box>
  );
};

export default Builder;
