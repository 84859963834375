import { CardContent, Typography, Box } from "@mui/material";
import "./styles.css";

type CardType = {
  image?: string;
  name: string;
  about: string;
};

const TeamCard = (props: CardType) => {
  const { image, name, about } = props;

  return (
    <Box className="card-container">
      {image ? (<img src={image} alt={name} />) : null}
      <CardContent>
        <Typography className="card-person-name">{name}</Typography>
        {/* <button className="download-btn">Download vcard</button> */}
        <Typography className="about">{about}</Typography>
      </CardContent>
    </Box>
  );
};

export default TeamCard;
