import axios from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { refreshAccessToken } from "../utils";

const useAxios = () => {
  const params = new URLSearchParams(location.search);
  const code = params.get("code");

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const accessToken = user.authorizationToken;
  const refreshToken = user.refreshToken;

  const customBaseUrlApi = useCallback(
    async ({ headers, ...rest }: any) => {
      try {
        const headersObj = {
          "Content-Type": "application/json",
          ...headers,
        };
        if (!code && accessToken) headersObj["Authorization"] = accessToken;
        const response = await axios({
          headers: headersObj,
          ...rest,
          validateStatus: (status) => status >= 200 && status <= 299,
        });

        return response;
      } catch (err: any) {
        if (err?.response?.status === 401 && refreshToken) {
          try {
            const _accessToken = await refreshAccessToken();
            window.location.reload();
          } catch (refreshError) {
            toast.error("Session Expired! Please login again", {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
            localStorage.removeItem("user");
            localStorage.removeItem("sessionStartedAt");
            navigate("/");
          }
        }
        throw err;
      }
    },
    [accessToken, refreshToken]
  );

  return customBaseUrlApi;
};

export default useAxios;
