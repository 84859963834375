import { basePvtURL, baseURL } from "../../../config";

export const publicRoutes = {
  market: {
    get: {
      query: "GET_ALL_MARKET",
      method: "GET",
      url: `${baseURL}/markets`,
    },
  },
  community: {
    get: {
      query: "GET_ALL_COMMUNITIES_FOR_PARTICULAR_MARKET",
      method: "GET",
      url: `${baseURL}/communities/`,
    },
  },
  projectedProperty: {
    get: {
      query: "GET_ALL_PROPERTY_FOR_MARKET_COMMUNITY",
      method: "GET",
      url: `${baseURL}/projected-property-values/`,
    },
  },
  searchCategories: {
    get: {
      query: "GET_ALL_CATEGORIES",
      method: "GET",
      url: `${baseURL}/search/`,
    },
  },
};
