import { Box, CircularProgress, CssBaseline, Toolbar } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { ContextProvider } from "../../providers/ContextProvider";
import { useAuth2Login } from "../../shared/hooks/private";
import { useUser } from "../../shared/hooks/private/useUser";
import { useInterval } from "../../shared/hooks/useInterval";
import useTimeSinceTabOpen from "../../shared/hooks/useTimeSinceTabOpen";
import { refreshAccessToken } from "../../shared/utils";
import { StaleConnectionContext } from "../ResumeWorkWrapper/staleConnectionContext";
import NavigationBar from "../navigation";

const REACQUIRE_TOKEN_AFTER_MINUTES = 55;
const VALIDATE_SESSION_TIMEOUT_MS = 60_000;

interface ProtectedRoutesProps {
  hasDashboardNavBar?: boolean;
}
const ProtectedRoutes = ({
  hasDashboardNavBar = true,
}: ProtectedRoutesProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { interruptForReload } = useContext(StaleConnectionContext);
  const code = searchParams.get("code");
  const user = useUser();
  const accessToken = user.authorizationToken;
  const { getTimeSinceTabOpen } = useTimeSinceTabOpen();
  const {
    data: loginData,
    mutate: loginFunc,
    error: loginError,
    isLoading: isAuthLoading,
  } = useAuth2Login();

  useEffect(() => {
    code && loginFunc(code);
  }, [code]);

  useInterval(
    async () => {
      const timeSincePageOpened = getTimeSinceTabOpen();
      const startedAtTimestamp = parseInt(
        localStorage.getItem("sessionStartedAt") ?? "0"
      );
      const threshold =
        startedAtTimestamp + REACQUIRE_TOKEN_AFTER_MINUTES * 60 * 1000;

      if (
        timeSincePageOpened > REACQUIRE_TOKEN_AFTER_MINUTES &&
        startedAtTimestamp > 0 &&
        new Date().getTime() > threshold
      ) {
        await refreshAccessToken();
        interruptForReload?.();
      }
    },
    isLoggedIn ? VALIDATE_SESSION_TIMEOUT_MS : null
  );

  useEffect(() => {
    if (loginError) {
      navigate("/");
    } else if (!isAuthLoading && !code && !loginData && !accessToken) {
      navigate("/");
    }
  }, [code, loginData, loginError, isAuthLoading, accessToken]);

  useEffect(() => {
    if (code && loginData) {
      searchParams.delete("code");
      setSearchParams(searchParams);
      localStorage.setItem("user", JSON.stringify(loginData));
      localStorage.setItem("sessionStartedAt", new Date().getTime().toString());
      setIsLoggedIn(true);
    } else if (!code && accessToken) {
      setIsLoggedIn(true);
    }
  }, [loginData, code]);

  return (
    <ContextProvider>
      {isLoggedIn ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <NavigationBar hasDashboardNavBar={hasDashboardNavBar} />
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
          >
            <Toolbar />
            {hasDashboardNavBar && (
              <>
                <Toolbar />
                <Toolbar className="custom-toolbar" />
              </>
            )}
            <Box
              sx={{
                width: "calc(100vw - 348px)",
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress size={30} />
        </Box>
      )}
    </ContextProvider>
  );
};

export default ProtectedRoutes;
