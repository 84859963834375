import React from "react";
import { Box, CssBaseline, Typography, useTheme } from "@mui/material";
import PageWrapper from "../../components/wrapper";
import contactInfo from "../../shared/fixtures/contact.json";
import "./styles.css";

const Contact: React.FC = () => {
  const { spacing, typography, palette } = useTheme();
  return (
    <Box display="flex" flexDirection="column">
      <CssBaseline />
      <PageWrapper minHeight="20vh" backgroundOpacity="0.4">
        <Typography
          className="sub-header"
          variant="body2"
          color={palette.success.light}
          sx={{ fontSize: "28px" }}
        >
          CONTACT US
        </Typography>
      </PageWrapper>
      <Box className="contact-container">
        <Box className="contact-box">
          <Box className="section-left">
            <Typography
              fontWeight={typography.fontWeightBold}
              fontSize={spacing(5)}
            >
              {contactInfo.taglines[0]} <br />
              <span
                style={{
                  color: palette.success.contrastText,
                }}
              >
                {contactInfo.taglines[1]}
              </span>
            </Typography>
          </Box>
          <Box className="section-right">
            <Typography className="section-right-title">
              {contactInfo.address.title}
            </Typography>
            <br />
            <Typography className="section-right-body">
              {contactInfo.address.line1}
            </Typography>
            <Typography className="section-right-body">
              {contactInfo.address.line2}
            </Typography>
            <br />
            <Typography className="section-right-body-2">
              {contactInfo.phone}
            </Typography>
            <br />
            <a
              className="section-right-body-2"
              href={`mailto:${contactInfo.email}`}
              target="_blank"
            >
              <Typography color={palette.success.contrastText}>
                {contactInfo.email}
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
