import React from "react";
import { Outlet } from "react-router-dom/dist";
import { Box } from "@mui/material";
import Navbar from "../../components/header";
import Footer from "../../components/footer";
import "./styles.css";

const Main: React.FC = () => {
  return (
    <Box className="main-container">
      <Navbar />
      <div style={{ flex: 1 }}>
        <Outlet />
      </div>
      <Footer />
    </Box>
  );
};

export default Main;
