import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React from "react";

type StatusSetter = React.Dispatch<React.SetStateAction<boolean>>;

interface dashboardDialogProp {
  setOpen: StatusSetter;
}

interface downloadInfoDialogProp {
  open: boolean;
  setOpen: StatusSetter;
  error: Error;
}

export const DashboardDialog = ({ setOpen }: dashboardDialogProp) => {
  return (
    <div className="custom-dialog-overlay">
      <div className="custom-dialog" style={{maxWidth: 800}}>
        <div className="custom-dialog-header">
          <DialogTitle className="custom-dialog-title">
            Welcome to the new AVA Dashboard
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpen(false);
                localStorage.setItem("landingPageDialogClosed", "true");
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent className="custom-dialog-content">
          <Typography variant="body1">
            The dashboard provides an overview
            of your account and access to all of the features our product
            offers. We hope you enjoy using our product and find these features
            helpful. If you have any further questions or feedback, please don't
            hesitate to reach out to us at <Link href="mailto:info@avatx.com?subject=AVA%20Dashboard%20Feedback" target="_blank" rel="noreferrer">info@avatx.com</Link>.
          </Typography>
        </DialogContent>
      </div>
    </div>
  );
};

export const DownloadErrorInfoDialog = ({
  open,
  setOpen,
  error,
}: downloadInfoDialogProp) => {
  const handleClose = () => setOpen(false);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Something went wrong, please try again
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{error?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const LogoutConfirmationDialog = (props: {
  logoutDialogOpen: boolean;
  handleCloseLogoutDialog: () => void;
  handleConfirmLogout: () => void;
}) => {
  const { logoutDialogOpen, handleCloseLogoutDialog, handleConfirmLogout } =
    props;
  return (
    <Dialog open={logoutDialogOpen} onClose={handleCloseLogoutDialog}>
      <DialogTitle>Confirm Logout</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to logout?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseLogoutDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmLogout} color="primary">
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DownloadConfirmationDialog = (props: {
  downloadDialogOpen: boolean;
  setDownloadDialogOpen: any;
  selectedCommunities: string[];
  handleDownloadConfirmation: (arg0: boolean) => void;
  isDownloadLoading: boolean;
}) => {
  const {
    downloadDialogOpen,
    setDownloadDialogOpen,
    selectedCommunities,
    handleDownloadConfirmation,
    isDownloadLoading,
  } = props;
  return (
    <Dialog
      open={downloadDialogOpen}
      onClose={() => setDownloadDialogOpen(false)}
    >
      <DialogTitle>Download All Data</DialogTitle>
      <DialogContent>
        <DialogContentText color="#40454F">
          Do you want to download the following communities?
        </DialogContentText>
        <DialogContentText>
          {selectedCommunities.map(
            (community, index) =>
              `${community}${
                index !== selectedCommunities.length - 1 ? ", " : ""
              }`
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDownloadConfirmation(false)}
          disabled={isDownloadLoading}
          color="primary"
          variant="outlined"
        >
          All Communities
        </Button>
        <Button
          onClick={() => handleDownloadConfirmation(true)}
          disabled={isDownloadLoading}
          color="primary"
          variant="contained"
        >
          Selected Communities
        </Button>
      </DialogActions>
    </Dialog>
  );
};
