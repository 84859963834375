import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback, useState } from "react";
import { StaleConnectionContext } from "./staleConnectionContext";

type ResumeWorkWrapperProps = {
  children?: React.ReactNode;
};

const ResumeWorkWrapper: React.FC<ResumeWorkWrapperProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    window.location.reload();
  };

  const interruptForReload = useCallback(() => {
    if (!open) {
      setOpen(true);
    }
  }, [setOpen]);

  return (
    <React.Fragment>
      <StaleConnectionContext.Provider value={{ interruptForReload }}>
        {children}
      </StaleConnectionContext.Provider>

      <Dialog maxWidth="xs" fullWidth open={open} disableEscapeKeyDown>
        <DialogTitle>Are you still working?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Reload to Resume
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ResumeWorkWrapper;
