import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { basePvtWebSocketURL, webSocketPingPongIntervalMS } from "../config";
import { useUser } from "../shared/hooks/private/useUser";
import { refreshAccessToken } from "../shared/utils";

export type FilterType = {
  category: string;
  value: string;
  sort: string;
};

type DownloadLoadingType = {
  type: string;
  requestId: string;
}[];

type ContextType = {
  filterValue: FilterType[];
  setFilterValue: Dispatch<SetStateAction<FilterType[]>>;
  isDownloadLoading: DownloadLoadingType;
  setIsDownloadLoading: Dispatch<SetStateAction<DownloadLoadingType>>;
  selectedBuilderMarket: BuilderMarket | null;
  setSelectedBuilderMarket: (bm: BuilderMarket | null) => void;
  websocketConnection: WebSocket | null;
  connectWebSocketAndListen: () => WebSocket;
  builderMarkets: BuilderMarket[];
};
export type BuilderMarket = {
  label: string;
  builderName: string;
  marketName: string;
};

const Context = createContext<ContextType | undefined>(undefined);

export const useCustomContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useContext must be used within a ContextProvider");
  }
  return context;
};

export const ContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [filterValue, setFilterValue] = useState<FilterType[]>([]);

  const [selectedBuilderMarket, setSelectedBuilderMarket] =
    useState<BuilderMarket | null>(null);

  const [isDownloadLoading, setIsDownloadLoading] =
    useState<DownloadLoadingType>([]);

  const navigate = useNavigate();
  const location = useLocation();

  const [websocketConnection, setWebsocketConnection] =
    useState<WebSocket | null>(null);
  const user = useUser();

  const accessToken = user?.authorizationToken;
  const websocketUrl = `${basePvtWebSocketURL}?Authorization=${accessToken}`;

  const startPingPong = (webSocket: WebSocket) => {
    const pingInterval = setInterval(() => {
      if (webSocket.readyState === WebSocket.OPEN) {
        webSocket.send(JSON.stringify({ action: "ping" }));
      }
    }, webSocketPingPongIntervalMS);
    return pingInterval;
  };

  // Create a WebSocket and listen for messages to trigger download
  const connectWebSocketAndListen = () => {
    const connection = new WebSocket(websocketUrl);

    connection.onopen = () => {
      setWebsocketConnection(connection);

      connection.addEventListener("message", (event) => {
        const { connectionId, requestId, signedUrl } = JSON.parse(event.data);
        // trigger download of a completed asset
        if (signedUrl) {
          const link = document.createElement("a");
          link.href = signedUrl;
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsDownloadLoading((prevIsDownloadLoading) => {
            return prevIsDownloadLoading.filter(
              (item) => item.requestId !== requestId
            );
          });
        } else if (!connectionId) {
          setIsDownloadLoading([]);
        }
      });
    };

    return connection;
  };

  const authorizedBuilderMarkets = user?.authorizedBuilderMarkets;
  const builderMarkets = useMemo<Array<BuilderMarket>>(() => {
    if (authorizedBuilderMarkets) {
      return authorizedBuilderMarkets
        .flatMap(({ builderName, markets }) =>
          markets.map(({ marketName }) => ({
            builderName,
            marketName,
            label: `${builderName} - ${marketName}`,
          }))
        )
        .sort((a, b) => -b.label.localeCompare(a.label));
    }
    return [];
  }, [authorizedBuilderMarkets]);

  useEffect(() => {
    (async () => {
      if (accessToken && !authorizedBuilderMarkets) {
        /**
         * If authorizedBuilderMarkets are unavailable
         * AND the access token exists, that means
         * The localStorage user is in bad state.
         * So we will refresh the token for new data.
         */

        await refreshAccessToken();
        window.location.reload();
      }
    })();
  }, [accessToken, authorizedBuilderMarkets]);
  // refresh the WebSocket connection any time accessToken changes
  useEffect(() => {
    if (accessToken) {
      const connection = connectWebSocketAndListen();
      const interval = startPingPong(connection);

      return () => {
        clearInterval(interval);
        connection.close();
      };
    }
  }, [accessToken]);

  const validateBuilderMarketOrRedirect = (value: BuilderMarket | null) => {
    if (!value) {
      /**
       * IMPORTANT to pass on search params when
       * redirecting to /select-builder-market
       * because if user is not logged in yet
       * we want to pass on the "code" to
       * the protected route component.
       */

      navigate({
        pathname: "/select-builder-market",
        search: location.search,
      });
    } else if (!location.pathname.startsWith("/dashboard")) {
      navigate("/dashboard");
    }
  };
  const handleOnChange = (value: BuilderMarket | null) => {
    setSelectedBuilderMarket(value);
    validateBuilderMarketOrRedirect(value);
  };

  useEffect(() => {
    validateBuilderMarketOrRedirect(selectedBuilderMarket);
  }, []);

  return (
    <React.Fragment>
      <Context.Provider
        value={{
          filterValue,
          setFilterValue,
          isDownloadLoading,
          setIsDownloadLoading,
          websocketConnection,
          connectWebSocketAndListen,
          selectedBuilderMarket,
          setSelectedBuilderMarket: handleOnChange,
          builderMarkets,
        }}
      >
        {children}
      </Context.Provider>
    </React.Fragment>
  );
};
