import Person1 from "../assets/images/person1.png";
import Person2 from "../assets/images/person2.png";
import Person3 from "../assets/images/person3.png";

const AboutUsItems = () => {
  return {
    titles: {
      section1: ["Expertise", "that best fits your needs."],
      section2: ["Our", "Team"],
    },
    descriptions:
      "Ad Valorem Advisors, AVA, is a full-service property tax advisory firm.  Established in 1999, AVA has been serving the metropolitan areas of Houston, Dallas/Fort Worth, San Antonio, and Austin.  The key to a successful business relationship starts with understanding our client’s business processes and determining how our expertise best fits their needs.  Our consulting and management services help balance the time and effort spent on property tax preparation, protesting, and compliance, while increasing your company's overall profitability.  This business model has allowed AVA to become one of the most well-respected property tax consulting firms in Texas.",
    features: [
      {
        title: "Personalized Approach",
        description:
          "While this is of major importance, Ad Valorem Advisors starts first with understanding your company's business goals to determining how our expertise best fits your needs.",
      },
      {
        title: "Balance Time & Effort",
        description:
          "Our consulting and management services help balance the time and effort spent on property tax preparation, protesting, and compliance, while increasing your company's overall profitability.",
      },
    ],
    team: [
      {
        image: Person1,
        name: "Toby Ware",
        about:
          "Mr. Ware’s real estate career began in Dallas with Deloitte, conducting appraisals on commercial and residential developments.  After moving back to Houston in 2004, Toby’s focus shifted to the homebuilding and land development industry.  As a result of working for homebuilders, developers, and private equity, Mr. Ware has developed a unique understanding of real estate valuation as it relates to property tax consulting and management services. Toby is a graduate from Texas A&M with a B.S. in Wildlife Fisheries and Sciences (1998) and a Masters in Land Economics and Real Estate (2001).",
      },
      {
        image: Person2,
        name: "Brad Colliander",
        about:
          "Mr. Colliander graduated from Texas A&M University with a B.S. in Agricultural Systems Management (2003) and a Masters in Land Economics and Real Estate (2005). Since then, Mr. Colliander has focused on analyzing real estate throughout the major markets in Texas. Working as a senior consultant for two national real estate consulting firms, Metrostudy and John Burns, as well as a land manager for Ryland Homes, Brad has developed a vast understanding of the real estate industry including land acquisition, development, and real estate valuation. This skill set has enabled Mr. Colliander to provide property tax consulting and management services to our clients.",
      },
      {
        image: Person3,
        name: "Trinka Turnbow",
        about:
          "Miss Turnbow joined AVA in 2006 after working at several property tax firms. With over 35 years in the property tax industry, her experience and expertise is unsurpassed. Trinka’s exquisite attention to detail has been beneficial in managing high profile clients with large portfolios.",
      },
    ],
  };
};

export default AboutUsItems;
