import { alpha, styled } from "@mui/material/styles";
import { GridRowId, gridClasses } from "@mui/x-data-grid";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import { useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material/";
import { Box, Button, TextField } from "@mui/material";
import { muiProKey } from "../../config";
import { useCustomContext } from "../../providers/ContextProvider";
import { convertCategoryFormat } from "../../shared/utils";
import ExportToExcel from "../downloadAction/ExportToExcel";
import VirtualizeAutoComplete from "../virtualizeAutoComplete";
import "./styles.css";

LicenseInfo.setLicenseKey(muiProKey || "");

const ODD_OPACITY = 0.2;
export const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: "#E9EBEE",
    color: theme.palette.text.secondary,
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "-0.084px",
  },
  [`& .${gridClasses.row}`]: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "-0.176px",
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    "&:hover, &.Mui-hovered": {
      backgroundColor: "#F7F8F9",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#F7F8F9",
    color: theme.palette.text.primary,
    "&:hover, &.Mui-hovered": {
      backgroundColor: "#F7F8F9",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  "& .emphasis": {
    fontWeight: 700,
  },
}));

export const BuilderStripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: "none",
  [`& .${gridClasses.row}`]: {
    color: "#111318",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .MuiDataGrid-row": {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F7F8F9",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#EEF2FE",
    },
    "&:hover": {
      backgroundColor: "#EFF1F3",
    },
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
    borderBottom: `1px solid ${theme.palette.grey["400"]}`,
  },
  "& .MuiDataGrid-columnHeader": {
    color: "#111318",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
    borderBottom: `2px solid ${theme.palette.common.black}`,
  },
  "& .emphasis": {
    fontWeight: 700,
  },
}));

type SearchToolbarProps = {
  selectedRows?: GridRowId[];
  searchIndex?: any;
  isFilterOpen?: boolean;
  handleFilterClick?: () => void;
  isDashboard?: boolean;
  setSearchedData?: any;
  downloadType: string;
  isSummary?: boolean;
};

export function QuickSearchToolbar(props: SearchToolbarProps) {
  const {
    selectedRows,
    searchIndex,
    isFilterOpen,
    handleFilterClick,
    isDashboard,
    setSearchedData,
    downloadType,
  } = props;

  const [filter, setFilter] = useState({
    category: "",
    value: "",
    sort: "",
  });
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const { filterValue, selectedBuilderMarket } = useCustomContext();
  const { builderName, marketName } = selectedBuilderMarket ?? {};

  const selectedData =
    selectedRows && selectedRows.length > 0 ? selectedRows : undefined;

  type PayloadDataType = {
    downloadType: string;
    builderName: string;
    marketName: string;
    selected?: any;
    filter?: {
      filterKey: string;
      filterValue: string;
    };
    communities: string[];
  };

  const selectedCommunities =
    (filterValue.length > 0 && filterValue?.map((item) => item.value)) || [];

  const payloadData: PayloadDataType = {
    downloadType: downloadType,
    builderName: builderName!,
    marketName: marketName!,
    communities: selectedCommunities,
  };

  if (selectedData) {
    payloadData.selected = selectedData;
  }
  if (filter && filter.category && filter.value) {
    payloadData.filter = {
      filterKey: convertCategoryFormat(filter.category),
      filterValue: filter.value,
    };
  }

  const options = [
    ...(searchIndex?.streetNames?.map((item: any) => ({
      category: "streetName",
      categoryName: "Street Name",
      sort: `1-${item}`,
      value: item,
    })) ?? []),
    ...(searchIndex?.jobNumbers?.map((item: any) => ({
      category: "jobNumber",
      categoryName: "Job Number",
      sort: `2-${item}`,
      value: item,
    })) ?? []),
    ...(searchIndex?.accountNumbers?.map((item: any) => ({
      category: "accountNumber",
      categoryName: "Account Number",
      sort: `3-${item}`,
      value: item,
    })) ?? []),
    ...(searchIndex?.referenceNumbers?.map((item: any) => ({
      category: "referenceNumber",
      categoryName: "CAD Reference No.",
      sort: `4-${item}`,
      value: item,
    })) ?? []),
  ];

  return (
    <Box className="toolbar-quick-filter">
      {!isDashboard ? (
        <VirtualizeAutoComplete
          sx={{ width: 400 }}
          id="grouped-search-bar"
          className="search-filter-bar"
          options={options.sort((a, b) => -b.sort.localeCompare(a.sort))}
          renderInput={(params: any) => (
            <TextField
              {...params}
              className="search-filter-input"
              label="Search"
              placeholder="filter by street, job, or account"
            />
          )}
          onChange={(event: any, value: any) => {
            setFilter(value);
            setSearchedData({
              category: value?.category,
              value: value?.value,
              sort: value?.sort,
            });
          }}
          isOptionEqualToValue={(option: any, value: any) =>
            option.value === value.value
          }
        />
      ) : (
        <div />
      )}
      <Box className="dashboard-table-actions">
        <Button
          className={
            selectedRows && selectedRows?.length > 0
              ? isFilterOpen
                ? "active-selected-row"
                : "active-filter"
              : ""
          }
          sx={{
            display: isDashboard ? "none" : null,
          }}
          variant="outlined"
          disabled={selectedRows && selectedRows.length < 1}
          onClick={handleFilterClick}
        >
          {isFilterOpen ? <Visibility /> : <VisibilityOff />}
          {isFilterOpen ? (
            <>
              <p>show unselected</p>
              <span className="selected-row-count">{selectedRows?.length}</span>
            </>
          ) : (
            <p>hide unselected</p>
          )}
        </Button>
        <ExportToExcel isDashboard={isDashboard} payloadData={payloadData} />
      </Box>
    </Box>
  );
}
