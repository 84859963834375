import { Button, Stack, TextField } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { BuilderMarket, FilterType } from "../../providers/ContextProvider";
import { useCommunitiesFilters } from "../../shared/hooks/private";
import CheckboxAutoComplete from "../virtualizeAutoComplete/CheckboxAutoComplete";

type CommunitySelectionProps = {
  onChange: (values: FilterType[]) => void;
  selectedCommunities: FilterType[];
  builderMarket: BuilderMarket;
  setIsCategoriesLoading: (isLoading: boolean) => void;
};

const CommunitySelection = ({
  builderMarket,
  selectedCommunities,
  onChange,
  setIsCategoriesLoading,
}: CommunitySelectionProps) => {
  const { data: categoriesData, isLoading: isCategoriesLoading } =
    useCommunitiesFilters(builderMarket.builderName, builderMarket.marketName);

  const options = useMemo(
    () =>
      categoriesData?.communities
        ?.map((item: any) => ({
          category: "Communities",
          sort: `1-${item}`,
          value: item,
        }))
        ?.sort((a: any, b: any) => -b.sort.localeCompare(a.sort)) ?? [],
    [categoriesData?.communities]
  );

  const handleSelectAll = () => {
    onChange(options);
  };
  const handleClear = () => {
    onChange([]);
  };

  useEffect(() => {
    onChange([]);
  }, [builderMarket]);

  useEffect(() => {
    setIsCategoriesLoading(isCategoriesLoading);
  }, [isCategoriesLoading]);

  return (
    <Stack width="100%">
      <CheckboxAutoComplete
        multiple
        options={options}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            className="search-filter-input"
            placeholder="type to select"
            label="Communities"
          />
        )}
        onChange={(_: any, values: FilterType[]) => onChange(values)}
        loading={isCategoriesLoading}
        value={selectedCommunities}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Button
          onClick={handleSelectAll}
          disabled={
            options.length === 0 ||
            selectedCommunities.length === options.length
          }
        >
          Select All
        </Button>
        <Button
          onClick={handleClear}
          disabled={options.length === 0 || selectedCommunities.length === 0}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  );
};

export default React.memo(CommunitySelection);
